/* TODO: use sass theming */
:root {
  --colorDefault: #6D635D;
  --colorDisabled: #ABA7A4;
  --colorPlaceholder: #ABA7A4;
}
  
.container {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.label {
  font-weight: 300;
  margin-bottom: 4px;
}

.label.isDisabled {
  color: var(--colorDisabled);
}

.textarea {
  width: 100%;
  height: 210px;
  color: var(--colorDefault);
  padding: 8px;
  border: 1px solid var(--colorDefault);
  border-radius: 4px;
}

.textarea::placeholder {
  font-weight: 300;
  color: var(--colorPlaceholder);
}

.textarea:focus {
  border-color: #7EB549;
  box-shadow: 0 0 0 0.2rem rgb(126 181 73 / 10%);
}

.textarea.hasError {
  border-color: #d50000;
}

.textarea.hasError:focus {
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 10%);
}

.textarea[disabled] {
  border-color: var(--colorDisabled);
}

.counterText {
  margin-left: auto;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
}

.counterText.isDisabled {
  color: var(--colorDisabled);
}
