.gallery-item {
        margin: 5px 0 10px 0;
        padding: 5px;
        border-radius: 10px;
}
.gallery-item:first-child {
        margin-left: 5px;
}

.gallery-image-container {
      height: 420px;
      width: 360px;
      border: 1px solid #ddd;
      border-radius: 10px;
      background-color: #B9BCBE;
}

.gallery-image-container img {
        object-fit: contain;
        width: 100%;
        height: 100%;
}
