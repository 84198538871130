.marker-label {
  text-align: center;
  box-sizing: border-box;
  overflow: visible !important;
  font-size: 18px;
}

.marker-initials {
  background-color: #df7300;
}

.control-container {
  margin: 10px;
  z-index: 0;
  left: 0px;
  top: 0px;
  font-size: 1rem;
}

.map-toolbar-container {
  display: flex;
  flex-wrap: wrap;
}

.map-toolbar-container .search-input {
  max-width: 420px;
}

.map-toolbar-container .search-input input,
.map-toolbar-container .segmented-control,
.map-toolbar-container .map-marker-titles {
  height: 40px;
  width: 420px;
}

.map-toolbar-container .search-input,
.map-toolbar-container .map-filter,
.map-toolbar-container .map-marker-titles {
  margin: 0 10px 10px 0;
}

.map-toolbar-container .segmented-control > * {
  white-space: nowrap;
  height: 100% !important;
  flex: 1;
}

.map-toolbar-container .segmented-control > * > * {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.map-filter .segmented-control > * {
  max-width: calc(420px / 3);
}

.map-marker-titles .segmented-control > * {
  max-width: calc(420px / 3.5);
}

.map-toolbar-container .search-input {
  min-width: 200px;
}

.control-button {
  border: none;
  font: inherit;
  cursor: pointer;
  outline: none;

  direction: ltr;
  overflow: hidden;
  text-align: center;
  position: relative;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  user-select: none;
  font-size: 18px !important;
  background-color: rgb(255, 255, 255);
  padding: 11px;
  background-clip: padding-box;
  border-radius: 2px 0 0 2px;
  border-right: 1px solid #e6e6e6;
}

.control-button.control-button-search {
  padding: 5px 10px;
}
.control-button span.control-button-subtitle {
  display: block;
  font-size: 12px;
}
.control-button:disabled {
  color: rgb(176, 176, 176);
  cursor: default;
}

.control-button + .control-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.control-button:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-right: none;
}

.vertical > .control-button {
  border-radius: 2px 2px 0 0;
  border-right: none;
  border-bottom: 1px solid #e6e6e6;
}

.vertical > .control-button + .control-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.vertical > .control-button:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom: none;
}

.control-button.active {
  color: #000;
  font-weight: 500;
  background-color: #ebebeb;
}

.control-button:focus {
  outline: none;
}

.control-button:hover:not(:disabled) {
  color: #000;
  background-color: #ebebeb;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  border-top: 3px solid #fff;
  position: absolute;
  top: 100%;
  left: calc(50% - 3px);
}

.map-container .search-input {
  font-size: initial;
  height: 38px;
}

.map-page .page-wrapper {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  flex-direction: column;
}

.map-page .map-column {
  max-width: 100%;
  height: 100%;
  margin: 0;
}

.map-page .column-layout {
  padding-right: 0;
  margin: 0;
}

.map-page .gm-bundled-control.gm-bundled-control-on-bottom {
  margin-bottom: 35px !important;
}

.map-toolbar,
.map-toolbar-horizontal {
  display: flex;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
}

.map-toolbar {
  flex-direction: column;
}

.map-toolbar-horizontal {
  flex-direction: row;
}

.map-toolbar-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 6px;
  cursor: pointer;
  background: transparent;
  border: none;
}

.map-toolbar-button:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.map-toolbar-horizontal .map-toolbar-button:not(:last-child) {
  border-right: 1px solid #e6e6e6;
  border-bottom: none;
}

.map-toolbar-button.company-button {
  padding-top: 7px;
  padding-bottom: 8px;
}

.map-toolbar-button i {
  color: #636363;
  font-size: 25px;
  display: inline-block;
  height: 25px;
}

.map-toolbar-button span {
  font-size: 12px;
  margin-top: 5px;
}

.map-toolbar-button.company-button i {
  margin-bottom: 2px;
}

.map-toolbar-button.user-location i {
  height: 31px;
}

.map-toolbar-button.user-location i:before {
  vertical-align: bottom;
}

.map-settings {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.map-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.map-filter .filter-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-radius: 5px;
  width: 127px;
  cursor: pointer;
  user-select: none;
}

.map-filter .filter-button.active {
  background-color: #7EB549;
}

.map-filter .filter-button span {
  color: #607d8b;
  padding: 5px 10px;
  font-size: 16px;
  text-align: center;
}

.map-filter .filter-button.active span {
  color: #fff;
}

.map-filter .filter-button:not(.active):hover span {
  color: #7EB549;
}

.map-filter,
.map-marker-titles {
  margin: 0;
}

.field-marker .marker-bubble:after,
.marker-image-comment .marker-bubble:after,
.marker-image-comment-cluster .marker-bubble:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: calc(50% - 4px);
  background-color: transparent;
  border-width: 4px;
  border-color: transparent;
  border-top-color: inherit;
  align-self: center;
  margin-top: 0px;
  border-style: solid;
}

.marker-label .hint .hint--content {
  transition-property: all;
  text-align: left;
  max-height: 240px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.map-marker-hint__content {
  height: 0;
  transition: height 0.35s cubic-bezier(0.485, 1.65, 0.545, 0.835);
  overflow: hidden;
}

.hint__content {
  font-size: 18px;
  line-height: initial;
  border-radius: 5px;
}

.map-marker-hint__content.map-marker-hint__content--visible {
  height: 40px;
}

.hint.hint--top:before {
  border-top-color: #fff;
}

.hint.hint--bottom:before {
  border-bottom-color: #fff;
}

.field-marker {
  transform: translateX(-50%);
}

.field-marker .hint.hint--top:before {
  margin-bottom: -7px;
}

.field-marker .hint--top .hint__content {
  margin-bottom: 5px;
}

.field-marker .hint.hint--bottom:before {
  margin-top: -7px;
}

.field-marker .hint--bottom .hint__content {
  margin-top: 5px;
}

.field-marker .hint__content {
  cursor: default;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
}

.field-marker .click-for-more {
  color: #555;
  font-size: 10px;
  font-style: italic;
  text-align: left;
}

.marker-bubble {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border-color: #c8c8c8;
  border-width: 0.5px;
  min-height: 24px;
}

.marker-bubble,
.current-location-marker,
.cluster-marker > div {
  will-change: transform;
  transition: transform 0.25s cubic-bezier(0.485, 1.65, 0.545, 0.835) 0s;
  transform: scale(1);
  transform-origin: 50% 100%;
}

.marker-label:hover .marker-bubble,
.marker-label .expanded .marker-bubble,
.current-location-marker:hover,
.cluster-marker:hover > div {
  transform: scale(1.2);
}

.field-marker .marker-bubble {
  color: #000;
}

.marker-bubble.opened {
        box-shadow: 6px 7px 10px 3px;
}

.field-marker .hint.hint--top:before {
  border-top-color: inherit;
}

.field-marker .hint.hint--bottom:before {
  border-bottom-color: inherit;
}

.field-marker .hint__content {
  text-align: left;
}

.field-marker .single-field {
  padding-left: 7px;
  padding-right: 5px;
}

.marker-label .hint__content {
  background-color: #fff;
  color: #000;
}

.marker-label .hint .title {
  font-weight: bold;
  text-align: left;
}

.marker-label .close-hint {
  display: none;
  cursor: pointer;
  color: #7EB549;
}

.marker-label .expanded .close-hint {
  display: block;
}

.marker-label .close-hint i {
  vertical-align: middle;
}

.worker-marker .marker-bubble {
  height: 35px;
  width: 35px;
  border-radius: 30px;
  border: 2px solid orange;
  color: orange;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.map-container .map-geo-search {
  width: 400px;
  height: 40px;
  margin-left: 10px !important;
}

.map-geo-search input {
  padding-right: 24px;
}

.map-geo-search .autocomplete-dropdown-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 0.25rem;
  margin-top: 1px;
  overflow: hidden;
}

.map-geo-search .suggestion-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  padding: 5px 10px;
}

.map-geo-search .suggestion-item:last-child {
  padding-bottom: 10px;
}

.map-geo-search .suggestion-item:first-child {
  padding-top: 10px;
}

.map-geo-search .suggestion-item i {
  color: #aaa;
  margin-right: 5px;
}

.map-geo-search .suggestion-item.active,
.map-geo-search .suggestion-item:hover {
  background-color: #f1f1f1;
}

.map-geo-search .autocomplete-dropdown-container .loading-container {
  background-color: #fff;
}

.polygon-marker-placement-toolbar.polygon-tools {
  flex-direction: column;
}

.polygon-marker-placement-toolbar .reset > i {
  font-size: 22px;
  line-height: 0;
}

.polygon-marker-placement-toolbar > .control-button {
  line-height: 0;
}

.polygon-marker-placement-toolbar > .control-button > i {
  vertical-align: middle;
}

.polygon-marker-placement-toolbar > .control-button.active,
.map-toolbar-container > .control-button.active {
  background: #7EB549;
  color: #fff;
}

.polygon-marker-placement-toolbar
  > .control-button:not(:disabled):not(.active):hover,
.fields-map-toolbar .control-button:not(:disabled):not(.active):hover,
.map-toolbar-button:not(:disabled):not(.active):hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.polygon-marker-placement-toolbar.polygon-tools
  > .control-button:not(:last-child).active {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.polygon-marker-placement-toolbar
  > .control-button.active
  i.fa-map-marker-slash {
  margin-left: -3px;
}

.polygon-marker-placement-toolbar .drawing-mode i.fa-plus {
  font-size: 12px;
  right: 4px;
  top: 6px;
  position: absolute;
}

.polygon-marker-placement-toolbar .measure-mode i.fa-ruler {
  margin-left: -3px;
}

.polygon-marker-placement-toolbar .hide-distance-markers span {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  font-size: 21px;
}

.map-message-container {
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  pointer-events: none;
}

.map-message {
  background-color: white;
  border-radius: 2px;
  padding: 6px 10px;
}

.polygon-marker-placement-undo-redo-toolbar {
  display: flex;
  min-width: 570px;
  margin-bottom: 25px;
  margin-left: 10px;
}

.map-page .polygon-marker-placement-undo-redo-toolbar {
  margin-bottom: 0;
  margin-left: 10px;
}

.position-on-map-with-nav-map .polygon-marker-placement-undo-redo-toolbar {
  min-width: 360px;
}

.polygon-marker-placement-undo-redo-toolbar .create-field-button,
.polygon-marker-placement-undo-redo-toolbar
  .fields-map-toolbar
  .create-field-button:not(:disabled):hover {
  height: 44px;
  color: rgb(86, 86, 86);
  background-color: #fff;
  border-color: #fff;
}

.polygon-marker-placement-undo-redo-toolbar .create-field-button:disabled {
  background-color: rgb(176, 176, 176);
}

.merged-control-container > div > .control-container {
  margin: 0;
}

.polygon-marker-placement-undo-redo-toolbar > div > .fields-map-toolbar {
  margin-left: 20px;
}

.polygon-marker-placement-toolbar .information-container {
  background-color: #ffffff;
  flex-direction: row;
  overflow: hidden;
  padding: 5px 10px;
  align-items: center;
  align-self: flex-start;
  display: flex;
  font-size: 18px;
  text-align: left;
  border-radius: 2px;
  height: 44px;
  margin-right: 20px;
}

.polygon-marker-placement-toolbar.info {
  justify-content: flex-end;
}

.information-container > div {
  margin-right: 0.5ex;
}

.position-on-map-with-nav-map
  .merged-control-container
  > div
  > .polygon-marker-placement-toolbar-search {
  margin: 10px 10px 10px 0;
}

.polygon-marker-placement-toolbar.polygon-tools
  > .control-button:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.merged-control-container > div > .control-container.reset-filter-container {
  margin-bottom: 50px;
}

.polygon-marker-placement-undo-redo-toolbar.merged-control-container
  > div
  > .control-container.reset-filter-container {
  margin-bottom: 0;
}

.hint__content .cropTag {
  padding: 5px 7px;
}

.cluster-marker {
  transform: translateX(-50%);
}

.cluster-marker > div {
  width: 100%;
  height: 100%;
}

.cluster-marker > div > div {
  white-space: nowrap;
  /*color:           #fff;*/
  height: 100%;
  width: 100%;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-marker:hover,
.cluster-marker:hover {
  z-index: 102 !important;
}

.company-marker .marker-container {
  display: flex;
  flex-direction: column;
}

.map-toolbar-search-container {
  display: flex;
}

.map-toolbar-filter-container {
  display: flex;
  flex-wrap: wrap;
}

.map-toolbar-control-overlay-container {
  flex-direction: column;
}

.map-toolbar-container.vertical {
  flex-direction: column;
}

.fields-map-controls-toolbar {
  align-items: center;
  width: 420px;
}

.fields-map-controls-toolbar .search-input {
  margin: 0;
  min-width: auto;
  flex: 1;
}

.fields-map-controls-toolbar .search-input input {
  width: 100%;
  box-shadow: none;
}

.fields-map-controls-toolbar .search-input input,
.fields-map-controls-toolbar .search-input input:focus {
  border: none;
}

.fields-map-controls-toolbar .search-input + button {
  border-left: 1px solid #e6e6e6;
}

.fields-map-filter-container {
  background-color: #fff;
  flex: 1;
  padding: calc(10px + 43px + 10px) 10px 0 10px;
  margin: -300px 0 0;
  transition: margin-top 0.2s;
}

.fields-map-filter-container.show {
  margin-top: 0;
}

.fields-map-controls-toolbar-container {
  display: flex;
  z-index: 2 !important;
}

.marker-image-comment .marker-bubble,
.marker-image-comment-cluster .marker-bubble {
  border-top-color: #fff;
}

.marker-image-comment:hover {
  z-index: 102 !important;
}

.marker-image-comment-cluster .point-count {
  position: absolute;
  top: -9px;
  right: -9px;
  min-width: 18px;
  padding-left: 3px;
  padding-right: 3px;
  height: 18px;
  border-radius: 9px;
  background-color: #7EB549;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  font-size: 12px;
}

.crop-age-filter {
  margin: 0 10px 10px 0;
  white-space: nowrap;
}

.crop-age-filter input {
  max-width: 70px;
}

.crop-age-filter *:not(:last-child) {
  margin-right: 10px;
}

.crop-age-filter .clear {
  padding: 0 10px;
  cursor: pointer;
}

.crop-age-filter .clear i:before {
  vertical-align: middle;
  line-height: 36px;
}

.map-control-button {
  margin: 10px;
}

.polygon-marker-placement-undo-redo-toolbar.position-bottom-center {
        top: unset !important;
        bottom: 26px !important;
        margin: 8px !important;
        left: 50% !important;
}

.fixed-width {
        width: 40px;
}

@media (min-width: 1270px) and (max-width: 1400px) {
  .fields-map-controls-toolbar,
  .fields-map-filter-container .segmented-control,
  .fields-map-filter-container .map-marker-titles {
    width: 385px;
  }

  .map-filter .segmented-control > * {
    max-width: calc(385px / 3);
  }

  .map-marker-titles .segmented-control > * {
    max-width: calc(385px / 3.5);
  }
}

@media (min-width: 1170px) and (max-width: 1270px) {
  .fields-map-controls-toolbar,
  .fields-map-filter-container .segmented-control,
  .fields-map-filter-container .map-marker-titles {
    width: 350px;
  }

  .map-filter .segmented-control > * {
    max-width: calc(350px / 3);
  }

  .map-marker-titles .segmented-control > * {
    max-width: calc(350px / 3.5);
  }
}

@media (max-width: 1170px) {
  .fields-map-controls-toolbar,
  .fields-map-filter-container .segmented-control,
  .fields-map-filter-container .map-marker-titles {
    width: 300px;
  }

  .map-filter .segmented-control > * {
    max-width: calc(300px / 3);
  }

  .map-marker-titles .segmented-control > * {
    max-width: calc(300px / 3.5);
  }
}
