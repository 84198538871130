.containerSuffix,
.container {
  border-bottom: 1px solid #D5D3D2;
  margin-top: 12px;
  margin-bottom: 20px;
  padding: 0 12px;
}

.containerSuffix td:first-child,
.container td:first-child {
  text-align: left;
}

.containerSuffix td:nth-child(2),
.container td:nth-child(2) {
  text-align: right;
}

.containerSuffix:last-child,
.container:last-child {
  border-bottom: 0;
}

.bold {
  font-weight: bold;
}

.section-header {
  font-weight: bold !important;
}
