.material-round {
    border: 1px solid #7EB549;
    border-radius: 39px;
    background: #7EB549;
    color: #FFFFFF;
    padding: 8px;
    margin: 7px 10px;
    font-size: 14px;
    height: 30px;
    width: 30px;
}

.material-round:hover {
    background: #7EB549;
}
