.profile, .profile-wrapper {
	flex:           1;
	display:        flex;
	flex-direction: column;
	min-height: 1px;
}

.profile .form-row.email {
	word-break: break-all;
}

.profile-dialog .modal-body {
	padding: 0;
	display: flex;
}

.profile-dialog .edit-button {
	cursor:     pointer;
	text-align: center;
	padding:    0 15px 0 0;
}

.profile-dialog .profile-tab-bar {
	display: flex;
}

.profile-dialog .profile-tab-bar .tab-view-list {
	flex: 1;
}
