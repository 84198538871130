/* TODO: use sass theming */
:root {
  --color: #6D635D;
  --colorDisabled: #ABA7A4;
  --colorPlaceholder: #ABA7A4;
}
  
.container {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 300;
  margin-bottom: 4px;
}

.label.isDisabled {
  color: var(--colorDisabled);
}

.input {
  font-size: 18px;
  line-height: 22px;
  color: var(--color);
  padding: 10px 8px;
  border: 1px solid var(--color);
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input:focus {
  border-color: #7EB549;
  box-shadow: 0 0 0 0.2rem rgb(126 181 73 / 10%);
}

.input::placeholder {
  font-weight: 300;
  color: var(--colorPlaceholder);
}

.input.hasError {
  border-color: #d50000;
}

.input.hasError:focus {
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 10%);
}

.input[disabled] {
  border-color: var(--colorDisabled);
}
