.field-sharing-requests {
    width: 100%;
}

.handle-request-btn-container {
    width: 100%;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
}

.handle-request-btn-text {
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
}
