.table-comment-date {
    display: inline-block;
    color: #8F8E94;
}

.table-date-cell-time {
    color: #8F8E94;
}

/*.fertilizing-header {*/
    /*font-size: 12px;*/
    /*line-height: 16px;*/
/*}*/
.fake-bonitur span,
.fertilizing-header span {
    display: inline-block;
    width: 55px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    /*color: #636363;*/
    /*border-right: 1px solid #EEEEEE;*/
}

.fake-bonitur span {
    width: 100px;
    text-align: right;
}

.fertilizing-header span:first-child {
    width: 15px;
    text-align: left;
    padding: 0;
    border-right: 0;
}
.fertilizing-header span:last-child {
    width: 60px;
    border-right: none;
}

.field-table .column-yield {
    text-align:    right;
}

.field-table .column-yield span {
    display: inline-block;
    width: 100px;
    text-align: right;
}

.field-table .table-container-2 {
    flex: 1;
}

.field-table .table-footer {
    padding:    5px;
    border-top: 1px solid #aaa;
}

.field-table .search-input {
    max-width: 350px;
    min-width: 100px;
}

.field-table .column-cropAge, .field-table .column-fieldSize, .field-table .column-distance {
    text-align: right;
}

.field-table .tab-view-list {
	flex:      1;
	/*max-width: 336px;*/
}

.field-table .date-range-filter {
    height: 38px;
}

.field-table .fertilizing-single-row-sum {
    display: inline-block;
}
