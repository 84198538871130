.fj-activation-line {
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px 10px 10px;
  position: relative;
  z-index: 1;
  height: 50px;
}

.fj-activation-loading {
  height: 100%;
  overflow: hidden;
}

.fj-activation-loading .loading-container {
  flex: 0;
}

.topbar ~ .fj-activation-line ~ .page-wrapper {
  padding-top: 110px;
  margin-top: -110px;
}
