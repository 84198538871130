.table-list-view-switch {
  display: inline-block;
  padding: 7px 0 0 14px;
  line-height: 1.1;
  text-align: center;
  color: #b9bcbe;
}

.table-list-view-switch.active {
  color: #636363;
}

.table-list-view-switch span {
  display: block;
  font-size: 10px;
}
