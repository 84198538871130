.latest-activity-container .tab-view-list {
	flex: 1;
}

.latest-activities-list {
	padding: 0 15px;
}

.latest-activities-list-container {
	flex:                       1;
	overflow:                   auto;
	-webkit-overflow-scrolling: touch;
}

.calendar-drop-down {
	padding: 0;
	border:  none;
}

.latest-activity-container .date-range-container {
	display:         flex;
	flex-direction:  row;
	align-items:     center;
	justify-content: center;
	margin:          0 auto;
}

.latest-activity-container .date-range {
	margin-left: 15px;
	font-size:   16px;
	color:       #636363;
}

.latest-activity-container .clear-date-range, .latest-activity-container .date-range {
	margin-top: 10px;
}

.latest-activity-container .clear-date-range i {
	font-size:      30px;
	margin-left:    15px;
	color:          #7EB549;
	line-height:    0;
	vertical-align: middle;
}
