.bonitur-table {
	display:        flex;
	flex:           1;
	flex-direction: column;
}

.bonitur {
	display:        flex;
	flex:           1;
	flex-direction: column;
	height:         100%;
	max-width:      100%;
}

.bonitur-table .date-range-filter-trigger {
	padding: 6px 10px;
        min-width: 200px;
}

.bonitur-table .bonitur-select {
	min-width: 200px;
}

.bonitur-footer {
	display:          flex;
	flex-direction:   row;
	justify-content:  space-between;
	padding:          10px;
	border-top-style: solid;
	border-top-width: 1px;
	border-top-color: #eee;
}

.bonitur-footer .date-time {
	color:     #8F8E94;
	font-size: 14px;
}

.bonitur-table .toolbar-container-left .search-input {
    max-width: 350px;
    min-width: 300px;
}
