.fieldset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.legend {
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: #3D312E;
  margin: 0;
}

.input:not(:checked), 
.input:checked {
  position: absolute;
  left: 0;
  opacity: 0;
}

.text {
  padding: 6px 10px;
  width: 116px;
  font-weight: 300;
  text-align: center;
  color: #3D312E;
  border: 1px solid #ABA7A4;
}

.text:hover {
  background-color: #F0EFEF;
}

.textHasError {
  border-color: #d50000;
}

.label {
  position: relative;
  height: 42px;
}

.label[data-name="no"] .text {  
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;  
}

.label[data-name="no"] .text:hover {
  color: #C53520;
}

.label[data-name="yes"] .text {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
}

.label[data-name="yes"] .text:hover {
  color: #7EB549;
}

.label[data-name="no"] .input:checked + .text {
  background-color: #C53520;
  color: #FFFFFF;
  border-color: transparent;
}

.label[data-name="yes"] .input:checked + .text {
  background-color: #7EB549;
  color: #FFFFFF;
  border-color: transparent;
}

.input:checked + .text {
  font-weight: 400;
}

.input:disabled + .text,
.input:disabled + .text:hover {
  color: #ABA7A4;
  background-color: transparent;
}
