.table-container {
	display:        flex;
        flex:           1 1 auto;  
	flex-direction: column;
        overflow:       hidden;
}

.table-container .cell, .table-container .header-cell, .table-container .group-cell {
	border:              0 solid #aaa3;
	border-right-width:  0px;
	border-bottom-width: 1px;
	padding:             5px;

	overflow:            hidden;
	text-overflow:       ellipsis;
	white-space:         nowrap;
}

.table-container .header-cell {
	padding-right:    20px;
	border-top-width: 0px;
	border-right:  1px solid #F0EFEF;
	cursor:           pointer;
	user-select:      none;
	font-weight:      400;
	border-bottom: 1px solid #EEEEEE;
}

.table-container .header-cell.no-sort {
	cursor: auto;
}

.table-container .header-title {
	flex: 1;
}

.table-container .group-cell {
	/*font-weight:      bold;*/
	background-color: #eeedf2;
	color: #000000;
}

.table-container .group-cell:not(.cell-column-last) {
	border-right: none;
}

.table-container .cell-column-0 {
	border-left-width: 0px;
}

.table-container .cell {
	cursor: pointer;
}

.table-container .cell.selected {
	background-color: #b3e2fe;
}

.table-container .drag-handle {
	position:  absolute;
	top:       0;
	right:     -5px;
	bottom:    0;
	width:     40px;
	cursor:    pointer;
	transform: none !important;
	z-index:   1;
}

.table-container .header-cell .drag-handle:hover, .table-container .header-cell .drag-handle.react-draggable-dragging {
	cursor: col-resize;
}

.table-container .sorting {
	display: none;
}

.table-container .sorting-asc, .table-container .sorting-desc {
	display: inline;
}

.table-container .sorting-asc:after {
	content:     "\f0de";
	font-family: 'Font Awesome 5 Pro';
	cursor:      pointer;
	font-weight: 900;

	top:         5px;
	position:    absolute;
	right:       5px;
}

.table-container .sorting-desc:after {
	content:     "\f0dd";
	font-family: 'Font Awesome 5 Pro';
	cursor:      pointer;
	font-weight: 900;

	top:         3px;
	position:    absolute;
	right:       1px;
}

.table-export-container {
	display: none;
}

.column-layout > .unlimited-column .scrollable-tab {
	overflow: visible;
}

.header-cell.column-cropRatingNotification {
        border-right: none;
}
