.employee-form-dialog .modal-body {
	background-color: #e5e5e5;
	padding:          0;
}

.employee-select-fields .buttons,
.employee-form-dialog .buttons {
	display:   flex;
	flex-grow: 1;
	flex-wrap: wrap;
	margin-right: 15px;
}

.employee-form-dialog .segmented-control-label {
	font-weight: bold;
}

.btn-fields-selection {
	font-size: 18px;
	font-weight: 300;
}

.employee-select-fields .search-input {
	margin-left: 0;
	margin-right: 10px;
}

.form.employee .segmented-control-wrapper-2 {
	text-align: right;
}
.form.employee  .segmented-control-container {
	text-align: center;
}

.crop_create_edit .segmented-control-label {
	width: 250px;
}
