.login, .register {
	background:              no-repeat center fixed url('../../public/images/login-background.jpg');
	-webkit-background-size: cover;
	-moz-background-size:    cover;
	-o-background-size:      cover;
	background-size:         cover;
	padding-top:             0;
	padding-right:           0;
	padding-left:            0;
	height:                  100vh;
	overflow:                hidden;
}

.login-register {
	position: relative;
	flex:     1;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
}

.login .card-body, .register .card-body {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.register .text-input-container {
	margin: 0;
}

.register .firstname-container {
	margin-right: 5px;
}

.register .firstname-container, .register .lastname-container {
	margin-bottom: 0;
	margin-top:    0;
}

.register h4 {
	margin-bottom: 15px;
	margin-top:    25px;
}

.register h4:first-child {
	margin-top: 0;
}

.register .mytooltip .tooltip-item {
	background-color: transparent;
	line-height:      0;
}

.register .mytooltip .tooltip-text {
	padding: 1.21em;
}

.register .mytooltip .tooltip-content {
	right:  100%;
	left:   auto;
	bottom: auto;
	top:    0;
	margin: 0;
	width:  320px;
}

.register .mytooltip .tooltip-content:after {
	top:          0;
	left:         100%;
	margin-left:  0;
	border-color: transparent;
	/*border-left-color: #2a3035;*/
}

.register .input-row {
	display:        flex;
	flex-direction: row;
	align-items:    center;
	margin:         15px 0 25px 0;
}

.register .country-tel-select {
	min-width: auto;
}

.btn-default {
	background: #fff;
	border-color: #7EB549;
	color: #7EB549;
}

.btn-default:hover, .btn-default:disabled:hover {
	background: #fff;
    box-shadow: 0 14px 26px -12px rgb(0 122 255 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 122 255 / 20%);
    border: 1px solid #7EB549;
	color: #7EB549;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state { background-color: #303030;
  opacity: 8%;
}


@media only screen and (max-width: 770px) {
	.login {
		background-position-x: 0;
	}
}
