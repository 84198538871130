.firebase-image {
	border-radius: 5px;
	cursor:        pointer;
}

.section-list > ul, .flat-list > ul, .section-list > ul > .section > ul {
	list-style-type: none;
	padding:         0;
}

.tab-view-list {
	display:         flex;
	border-bottom:   none;
	justify-content: center;
}

.tab-view-list .nav-link.active {
	background-color: transparent;
	font-weight:      bold;
}

.tab-view-list .nav-link {
	position: relative;
}

.tab-view-list .nav-link .count-badge {
	right: 0;
}

.toolbar-container {
	display:         flex;
	flex-direction:  row;
	flex-wrap:       wrap;
	padding:         5px 10px;
}

.toolbar-container > * {
	margin-top:    5px;
	margin-bottom: 5px;
}

.toolbar-container > *:not(:first-child) {
	margin-left: 5px;
}

.toolbar-container > *:not(:last-child) {
	margin-right: 5px;
}

.toolbar-container-left {
        display: flex;
}
.toolbar-container-left > *:not(:first-child) {
	margin-left: 5px;
}
.toolbar-container-left > *:not(:last-child) {
	margin-right: 5px;
}


.search-input {
	display:             flex;
	flex:                1px;
	flex-direction:      row;
	border-bottom-width: 1px;
	align-items:         center;
	position:            relative;
}

.search-input > * {
	margin-top:    0;
	margin-bottom: 0;
}

.search-input .text-input-container {
	margin-top: 0;
}

.search-input input {
	padding-left: 30px;
}

.search-input .fj-search,
.search-input .clear,
.search-input .loading {
	position: absolute;
	top:      1px;
	bottom:   0;
	z-index:  1;
	padding:  0 10px;
}

.search-input .fj-search:before, .search-input .clear i:before {
	vertical-align: middle;
	line-height:    36px;
}

.search-input .fj-search {
	left:           0;
	pointer-events: none;
}

.search-input .clear, .search-input .loading {
	right:  0;
	cursor: pointer;
}

.favorites {
	display: inline-block;
}

.favorites .list > li {
	display:        flex;
	flex-direction: row;
	cursor:         pointer;
	padding:        5px 10px;
}

.favorites .list > li:nth-child(odd) {
	background-color: #f0eeee;
}

.favorites .fav-label {
	display:         flex;
	align-items:     center;
	justify-content: space-between;
	flex-direction:  row;
	flex:            1;
}

.favorites .fav-label + .btn {
	margin-left: 15px;
}

.favorites .menu {
	min-width: 300px;
}

.text-input-container {
	position:   relative;
	/*margin-top: 15px;*/
	flex:       1;
}

.text-input-container .form-control:focus {
	border-bottom: 1px solid transparent;
}

.tab-view-list .nav-item {
	margin-bottom: 0;
}

.tab-view-list li a.nav-link {
	padding:    10px 20px 5px;
	color:      #607d8b;
	text-align: center;
}

.tab-view-list li a.nav-link .width-spacer {
	display:     block;
	height:      0;
	font-weight: bold;
	visibility:  hidden;
}

.scrollable-tab {
	flex:     1 1;
	overflow: auto;
	display:  none;
	margin-top: 5px;
	-webkit-overflow-scrolling: touch;
}

.scrollable-tab.active {
	display: flex;
}

.help-block, .error-block {
	margin-top: 5px;
}

.error-block {
	color: #fc4b6c;
}

.sort-filter,
.sort-filter:not(:disabled):not(.disabled):hover,
.sort-filter:not(:disabled):not(.disabled):focus,
.sort-filter:not(:disabled):not(.disabled):active {
	border:           none;
	background-color: transparent !important;
}

.sort-filter:not(:disabled):not(.disabled):active:focus {
	box-shadow: none;
}

.sort-filter i {
	font-size: 20px;
}

.list-item {
	padding:       5px 20px 5px 12px;
	border-bottom: 1px solid #d5cccc;
}

.list-item.selected {
	background-color: rgba(0,122,255, 0.06);
}

.form-row {
	display:         flex;
	flex:            1;
	flex-direction:  column;
	justify-content: space-between;
	border-bottom:   1px solid #C8C8C8;
	margin-top:      5px;
	margin-bottom:   5px;
	padding-top:     5px;
	padding-bottom:  5px;
}

.navbar-basic-title {
	flex:            1;
	display:         flex;
	flex-direction:  column;
	align-items:     center;
	justify-content: center;
}
.navbar-basic-title--left-aligned {
	align-items:     start !important;
        margin-left:     15px;
}

.count-badge {
	font-size:        15px;
	position:         absolute;
	top:              3px;
	right:            -6px;
	background-color: #fc4b6c;
	color:            #fff;
	line-height:      1;
	min-width:        18px;
	border-radius:    9px;
	font-weight:      bold;
	padding:          2px;
}

.tag {
	display:         flex;
	align-items:     flex-start;
	justify-content: flex-start;
	line-height:     1;
}

.tag > div {
	border-radius:  5px;
	padding:        5px;
	flex-direction: row;
}

.clickable-option {
        cursor: pointer;
        padding: 0 0.5em;
        margin: 0.3em 0;
}
.clickable-option:hover {
        background-color: rgb(126, 181, 73);
        color: white;
        border-radius: 5px;
}
