.date-range-filter-trigger {
	background-color: #fff;
	align-items:      center;
	padding:          5px 10px;
	flex-direction:   row;
	justify-content:  space-between;
	color:            #636363;
	display:          flex;
	border:           1px solid #ced4da;
	border-radius:    0.25rem;
	min-width:        300px;
	cursor:           pointer;
}

.date-range-filter-trigger > div {
	display:        flex;
	flex-direction: row;
	flex:           1;
	align-items:    center;
	margin-left:    10px;
}

.date-range-filter-trigger > div > * {
	margin-right: 10px;
}

.date-range-filter-menu {
	display:    flex;
	padding:    0;
	min-height: 253px;
}

.date-range-filter-menu.show {
	display: flex;
}

.date-range-filter-menu .options li a {
	padding:     8px 16px;
	display:     flex;
	align-items: center;
}

.date-range-filter-menu .rc-calendar {
	border:     none;
	box-shadow: none;
}

.date-range-filter-menu .list-style-none li a span {
	float: none;
}

.date-range-filter-menu .options li a > .fj-label-no-icon {
	flex: 1;
}
