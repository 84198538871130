.fj-label-no-icon {
    color: #000000;
}

.fj-label-no-icon.active {
    color: #7EB549;
}

.list-section .item,
.list-section .item-active {
    cursor: pointer;
    color: #000;
    font-size: 16px;
}

.list-section .item-active {
    color: #7EB549;
}

.filter-list .section-headline {
    color: #6D635D;
}
.list-section .section-headline {
    color:  #817B77;
}

.section-sublist .dropdown-item{
    padding-left: 30px;
    color: black;
}