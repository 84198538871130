.container {
    font-weight: bold;
    margin-right: 5px;
    text-align: right;
}

.containerNitrate {
    font-size: 17px;
    font-weight: 600;
}

.containerLeftAlignedNormal {
    text-align: left;
    font-weight: normal;
}

