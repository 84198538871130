.dropdown-menu {
	box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.dropdown-menu-left {
	right: auto;
	left:  0;
}

.dropdown-menu-bottom-left {
	right:  auto;
	left:   0;
	top:    auto;
	bottom: 100%;
}

.dropdown-menu-bottom-right {
	right:  0;
	left:   auto;
	top:    auto;
	bottom: 100%;
}

.drop-down-portal {
	position: relative;
	z-index:  10000;
}

.dropdown-menu.show-where-clicked {
	position: fixed;
}