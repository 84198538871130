/* TODO: use sass theming */
:root {
  --widthSuffixContainer: 30px;
  --colorDisabled: #aba7a4;
  --colorPlaceholder: #aba7a4;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-weight: 300;
  margin-bottom: 4px;
}

.label.isDisabled {
  color: var(--colorDisabled);
}

.containerInput {
  position: relative;
  width: 120px;
  border-radius: 4px;
}

.input {
  border: none;
  width: 100%;
  padding: 10px 8px;
  -webkit-appearance: none;
  text-align: center;
}

.input.hasSuffix {
  padding-right: calc(var(--widthSuffixContainer) + 10px);
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:focus + .containerSuffix {
  border-color: #7eb549;
  box-shadow: 0 0 0 0.2rem rgb(126 181 73 / 10%);
}

.input.hasError + .containerSuffix {
  border-color: #d50000;
}

.input.hasError:focus + .containerSuffix {
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}

.input::placeholder {
  font-weight: 300;
  color: var(--colorPlaceholder);
}

.containerSuffix {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #6d635d;
  border-radius: 4px;
  pointer-events: none;
  padding-right: 8px;
}

.description {
  display: block;
  color: #aba7a4;
  font-style: italic;
}

.suffix {
  font-weight: 300;
  margin-left: auto;
  width: var(--widthSuffixContainer);
  line-height: 16px;
  padding-top: 2px;
}

.input[disabled] + .containerSuffix {
  border-color: var(--colorDisabled);
  color: var(--colorDisabled);
}

.inputWrapper {
  display: inline-flex;
  align-items: center;
}

.inputWrapper span {
  margin-right: 10px;
}
