/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Preloader*/
.preloader {
	width:      100%;
	height:     100%;
	top:        0px;
	position:   fixed;
	z-index:    99999;
	background: #fff;
}

.preloader .cssload-speeding-wheel {
	position: absolute;
	top:      calc(50% - 3.5px);
	left:     calc(50% - 3.5px);
}

/*******************
/*Top bar
*******************/
.topbar {
	color:      #61af3c;
	background: #fff;
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
	display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
	display: inline-block;
	color:   rgba(255, 255, 255, 0.8);
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
	color: #ffffff !important;
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:hover, .topbar .navbar-light .navbar-nav .nav-item > a.nav-link:focus {
	color: rgba(255, 255, 255, 0.8) !important;
}

/*******************
/*General Elements
*******************/
a {
	color: #6D635D;
}

a.link:hover, a.link:focus {
  color: #7EB549 !important;
}

.bg-theme {
  background-color: #7EB549 !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #7EB549;
  border-color: #7EB549;
}

.right-sidebar .rpanel-title {
  background: #7EB549;
}

.stylish-table tbody tr:hover, .stylish-table tbody tr.active {
  border-left: 4px solid #7EB549;
}

.text-themecolor {
  color: #7EB549 !important;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #7EB549;
  color: #7EB549;
}

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #7EB549;
}

/*******************
/*Buttons
*******************/
.btn-themecolor,
.btn-themecolor.disabled {
  background: #7EB549;
	color:      #ffffff;
  border: 1px solid #7EB549;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #7EB549;
	opacity:    0.7;
  border: 1px solid #7EB549;
}

.btn-themecolor.active, .btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:focus {
  background: #6D635D;
}

.btn-success,
.btn-success.disabled {
	background: #6D635D;
	border: 1px solid #6D635D;
	-webkit-box-shadow: 0 2px 2px 0 rgba(97, 175, 60, 0.14), 0 3px 1px -2px rgba(97, 175, 60, 0.2), 0 1px 5px 0 rgba(97, 175, 60, 0.12);
	box-shadow: 0 2px 2px 0 rgba(97, 175, 60, 0.14), 0 3px 1px -2px rgba(97, 175, 60, 0.2), 0 1px 5px 0 rgba(97, 175, 60, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in; }
.btn-success:hover,
.btn-success.disabled:hover {
	background: #6D635D;
	-webkit-box-shadow: 0 14px 26px -12px rgba(97, 175, 60, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(97, 175, 60, 0.2);
	box-shadow: 0 14px 26px -12px rgba(97, 175, 60, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(97, 175, 60, 0.2);
	border: 1px solid #6D635D; }
.btn-success.active, .btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:focus {
	background:         #6D635D;
	-webkit-box-shadow: 0 14px 26px -12px rgba(97, 175, 60, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(97, 175, 60, 0.2);
	box-shadow:         0 14px 26px -12px rgba(97, 175, 60, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(97, 175, 60, 0.2); }

/*******************
/*sidebar navigation
*******************/
.sidebar-nav {
	background: #fff;
}

.sidebar-nav ul li a {
	color: #6D635D;
}

.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
  color: #7EB549;
}

.sidebar-nav ul li a.active {
	color: #263238;
}

.sidebar-nav ul li.nav-small-cap {
	color: #99abb4;
}

.sidebar-nav > ul > li.active > a, .sidebar-nav > ul > li.active:hover > a {
	color: #7EB549;
}

.sidebar-nav > ul > li.active > a i, .sidebar-nav > ul > li.active:hover > a i {
	color: #7EB549;
}

.sidebar-nav > ul > li.active > a:after, .sidebar-nav > ul > li.active:hover > a:after {
	border-color: #ffffff;
}

.sidebar-nav > ul > li > a.active i, .sidebar-nav > ul > li > a:hover i {
  color: #7EB549;
}

.sidebar-nav > ul > li > a i {
	color: #6D635D;
}

.companies .sidebar-nav > ul > li > a i {
	color: #ffffff;
}

.sidebar-nav > ul > li > a.active {
	font-weight: 400;
	background:  #ffffff;
	color:       #7EB549;
}

.sidebar-nav #sidebarnav li {
	margin-left: 0.2em;
}

.topbar ul.dropdown-user li a:hover {
	background:      #f2f4f8;
	color:           #7EB549;
	text-decoration: none;
}

/*******************
Table-Data Table
******************/

.ReactTable {
	font-size:   16px;
	line-height: 24px;
}

input.theme-box {
	border:              0;
	background-image:    -webkit-gradient(linear, left top, left bottom, from(#7EB549), to(#7EB549)), -webkit-gradient(linear, left top, left bottom, from(#d9d9d9), to(#d9d9d9));
	background-image:    -webkit-linear-gradient(#7EB549, #7EB549), -webkit-linear-gradient(#d9d9d9, #d9d9d9);
	background-image:    -o-linear-gradient(#7EB549, #7EB549), -o-linear-gradient(#d9d9d9, #d9d9d9);
	background-image:    linear-gradient(#7EB549, #7EB549), linear-gradient(#d9d9d9, #d9d9d9);
	background-size:     0 2px, 100% 1px;
	background-repeat:   no-repeat;
	background-position: center bottom, center calc(100% - 1px);
	background-color:    transparent;
	-webkit-transition:  background 0s ease-out;
	-o-transition:       background 0s ease-out;
	transition:          background 0s ease-out;
	float:               none;
	-webkit-box-shadow:  none;
	box-shadow:          none;
	border-radius:       0;
	width:               100%;
	padding:             0;
}

input.theme-box:focus {
	outline:                     none;
	background-image:            -webkit-gradient(linear, left top, left bottom, from(#7EB549), to(#7EB549)), -webkit-gradient(linear, left top, left bottom, from(#d9d9d9), to(#d9d9d9));
	background-image:            -webkit-linear-gradient(#7EB549, #7EB549), -webkit-linear-gradient(#d9d9d9, #d9d9d9);
	background-image:            -o-linear-gradient(#7EB549, #7EB549), -o-linear-gradient(#d9d9d9, #d9d9d9);
	background-image:            linear-gradient(#7EB549, #7EB549), linear-gradient(#d9d9d9, #d9d9d9);
	background-size:             100% 2px, 100% 1px;
	-webkit-box-shadow:          none;
	box-shadow:                  none;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration:      0.3s;
	transition-duration:         0.3s;
}

.ReactTable .rt-thead .rt-tr .rt-th, .ReactTable .rt-thead .rt-tr .rt-td {
	padding: .75rem;
}

.ReactTable .rt-table .rt-thead.-header {
	-webkit-box-shadow: none;
	box-shadow:         none;
}

.ReactTable .rt-thead.-header .rt-th.-sort .rt-resizable-header-content,
.ReactTable .rt-thead.-header .rt-th.-sort-asc .rt-resizable-header-content,
.ReactTable .rt-thead.-header .rt-th.-sort-desc .rt-resizable-header-content,
.ReactTable .rt-thead .sorting_asc_disabled,
.ReactTable .rt-thead .sorting_desc_disabled {
	background: transparent;
}

.ReactTable .rt-thead.-header .rt-th.-sort-asc:after {
	content:     "\f0de";
	margin-left: 10px;
	font-family: 'Font Awesome 5 Pro';
	cursor:      pointer;
	font-weight: 900;

	top:         13px;
	position:    absolute;
	right:       1px;
}

.ReactTable .rt-thead.-header .rt-th.-sort-desc:after {
	content:     "\f0dd";
	margin-left: 10px;
	font-family: 'Font Awesome 5 Pro';
	cursor:      pointer;
	font-weight: 900;

	top:         11px;
	position:    absolute;
	right:       1px;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc, .ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
	-webkit-box-shadow: none !important;
	box-shadow:         none !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-th {
	border: 0px;
}

.ReactTable.table-striped .rt-tbody .rt-tr.-odd {
	background: #f2f4f8;
}

.ReactTable.table-hover .rt-tbody .rt-tr:hover {
	background-color: #e6fcff;
}

.ReactTable .rt-td, .ReactTable .rt-th {
	border-color: #f3f1f1;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-th {
	font-weight: 500;
}

.ReactTable .rt-thead .rt-tr {
	text-align: left;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
	border-right: 0 !important;
}

.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: none;
}

.left-sidebar {
	position: absolute;
}

.form-control {
	color: #6D635D;
}

.form-control:focus {
	box-shadow: 0 0 0 0.2rem rgb(126 181 73 / 25%);
}


.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
	background-color: #7EB549;
	border: 1px solid #7EB549; }

.btn-primary,
.btn-primary:disabled {
	background: #7EB549;
	border: 1px solid #7EB549;
	-webkit-box-shadow: 0 2px 2px 0 rgba(0,122,255, 0.14), 0 3px 1px -2px rgba(0,122,255, 0.2), 0 1px 5px 0 rgba(0,122,255, 0.12);
	box-shadow: 0 2px 2px 0 rgba(0,122,255, 0.14), 0 3px 1px -2px rgba(0,122,255, 0.2), 0 1px 5px 0 rgba(0,122,255, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in; }
.btn-primary:hover,
.btn-primary:disabled:hover {
	background: #7EB549;
	-webkit-box-shadow: 0 14px 26px -12px rgba(0,122,255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0,122,255, 0.2);
	box-shadow: 0 14px 26px -12px rgba(0,122,255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0,122,255, 0.2);
	border: 1px solid #7EB549; }
.btn-primary.active, .btn-primary:focus,
.btn-primary:disabled:active,
.btn-primary:disabled:focus {
	background: #7EB549;
	-webkit-box-shadow: 0 14px 26px -12px rgba(0,122,255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0,122,255, 0.2);
	box-shadow: 0 14px 26px -12px rgba(0,122,255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0,122,255, 0.2); }
.btn-primary:not(:disabled):not(.disabled):active {
	background-color: #6D635D !important;
    border-color: #6D635D !important;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
	box-shadow: 0 0 0 0.2rem rgb(126 181 73 / 50%);
}

.floating-labels .form-control {
	padding-bottom: 5px;
}

.comment-form .fj-send {
	cursor: pointer;
}

.table-striped tbody tr:nth-of-type(odd) {
	background: #fafafa;
}

.custom-select {
	color:        #000;
	border-color: #727b84;
}


.fj-btn {
	min-width:     290px;
	border-radius: 25px;
	position: relative;
	padding: 5px 0;
	font-weight: bold;
}

.fj-btn-with-icon i{
	position: absolute;
	left: 15px;
	top: 8px;
	font-size: 16px;
}

.nav-tabs .nav-item {
	cursor: pointer;
}
