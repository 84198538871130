.company {
	flex:           1;
	display:        flex;
	flex-direction: column;
}

.tabbar {
	display: flex;
	justify-content: space-around;
	margin: 1em auto;
}

.tabbar > .tab {
	flex: 1;
	text-align: center;
	margin: 0;
}
.tabbar > .tab.active{
	border-bottom: 1px solid rgb(97,175,60);
}
