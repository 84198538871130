.mark-container {
    flex-direction: row;
    align-items:    center;
    display:        flex;
}

.mark-container .badge-container {
    align-items:    center;
    display:        flex;
    flex-direction: column;
    margin-right: 5px;
}

.mark-container .reason {
    font-size:   16px;
    color:       #4A4A4A;
    white-space: pre-line;
}

.field-mark-badge {
    font-size: 14px;
}
