.companies {
	width:          60px;
	overflow:       visible;
	position:       absolute;
	z-index:        20;
	background:     #6D635D;
	color:          #fff;
	top:            60px;
	left:           0;
	bottom:         0;
	box-shadow:     1px 0px 20px rgba(0, 0, 0, 0.08);
	display:        flex;
	flex-direction: column;
}

.companies.open {
	width: 240px;
}

.companies .company-list {
	list-style-type: none;
}

.companies .company {
	min-height: 60px;
	display:    flex;
	cursor:     pointer;
}

.companies .company .info {
	flex:    1;
	padding: 5px;
}

.companies .company .info .street, .companies .company .info .country {
	color: #aaa;
}

.companies .company .name {
	flex:          1;
	text-align:    left;
	text-overflow: ellipsis;
	overflow:      hidden;
}

.companies .company .summary {
	margin-bottom: 5px;
}

.companies:not(.open) .sidebar-nav #sidebarnav li {
	position: relative;
	overflow: visible;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li > .info {
	position:     absolute;
	left:         60px;
	top:          55px;
	width:        240px;
	z-index:      1001;
	background:   #181c22;
	padding-left: 1px;
}

.companies:not(.open) .left-sidebar {
	position: absolute;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li:hover > .info {
	height:   auto !important;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li:hover > .info,
.companies:not(.open) .sidebar-nav #sidebarnav > li:hover > .info.collapse {
	display: block;
}

.companies:not(.open) .left-sidebar {
	width: 60px;
}

.companies:not(.open) .scroll-sidebar {
	padding-bottom: 0px;
	position:       absolute;
}

.companies:not(.open) .hide-menu,
.companies:not(.open) .nav-small-cap,
.companies:not(.open) .sidebar-footer {
	display: none;
}

.companies:not(.open) .nav-devider {
	width: 50px;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li > a, .companies .toggle-companies-container .toggle-companies {
	padding:            9px;
	-webkit-transition: none !important;
	-o-transition:      none !important;
	transition:         none !important;
	width:              50px;

	display:            flex;
	flex-direction:     row;
	align-items:        center;
	justify-content:    center;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li:hover > a, .companies .toggle-companies-container:hover .toggle-companies {
	background:         #7EB549;
	color:              #ffffff !important;
	-webkit-transition: none !important;
	-o-transition:      none !important;
	transition:         none !important;
	border-color:       #7EB549;
	justify-content:    flex-start;
	width:              auto;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li.company:hover > a {
	min-width: 300px;
	width:     300px;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li:hover > a i, .companies .toggle-companies-container:hover .toggle-companies i {
	color: #ffffff;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
	display: inline;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li.active > a {
	border-color: transparent;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li.active > a.active {
	border-color: #7EB549;
}

.companies:not(.open) .sidebar-nav #sidebarnav > li > a .name {
	margin-left: 15px;
}

@media (max-width: 767px) {
	.companies .left-sidebar {
		position: fixed;
	}
}

@media (max-width: 1024px) and (min-width: 700px) {
	.sidebar-nav ul li a i {
		display: inline-block;
	}
}

.companies .sidebar-nav, .companies .toggle-companies-container {
	padding: 5px;
}

.companies .toggle-companies-container {
	display: inline-block;
}

.companies .toggle-companies {
	border-radius: 4px;
	color:         #ffffff;
}

.companies.open .sidebar-nav {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.companies:not(.open) .sidebar-nav #sidebarnav li {
	margin:  0;
	display: inline-block;
}

.companies .sidebar-nav #sidebarnav li {
	flex-wrap: wrap;
	margin:    0;
}

.companies .sidebar-nav {
	background: transparent;
	flex:       1 1;
}

.companies .sidebar-nav li a {
	display:     flex;
	align-items: center;
}

.companies.open .sidebar-nav li a .avatar {
	margin-right: 10px;
}

.companies.open .sidebar-nav li:not(.active) .info {
	display: none;
}

.companies.open .sidebar-nav li, .companies.open .sidebar-nav a {
	width: 100%;
}

.companies.open .add-new-company-icon {
	margin-right: 10px;
}

.companies ul li a {
	padding: 9px;
}

.invites {
	padding: 15px;
}

.companies .sidebar-nav > ul > li.active > a, .sidebar-nav > ul > li.active:hover > a {
	color:      #ffffff;
	background: #7EB549;
}

.companies .sidebar-nav > ul > li.active > a i, .sidebar-nav > ul > li.active:hover > a i {
	color: #ffffff;
}

.companies .count-badge {
	right: auto;
	left:  37px;
}

.btn:disabled {
  color: #ffffff;
  cursor: default;
}
