.boundary-error {
	position:         absolute;
	top:              0;
	left:             0;
	right:            0;
	bottom:           0;

	display:          flex;
	justify-content:  center;
	align-items:      center;
}

.boundary-error .card {
	min-width: 300px;
	max-width: 400px;
}