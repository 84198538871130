.sidebar-nav {
	min-height: 52px;
}

.sidebar-nav > ul > li {
	display:  inline-block;
	position: relative;
}

.sidebar-nav {
	padding: 0;
}

.navigation-bar {
	line-height: 1.5;
	flex-grow: 1;
}

.navigation-bar .sidebar-nav > ul > li > a {
	font-size: 16px;
}

.navigation-bar .sidebar-nav > ul > li > a i {
	vertical-align: text-bottom;
}

.top-nav .sidebar-nav > ul > li.active:hover > a {
	background: none;
	color: #7EB549;
}
.top-nav .sidebar-nav > ul > li.active:hover > a i {
	background: none;
	color: #7EB549;
}
.navigation-bar #sidebarnav a > span:not(.count-badge) {
	display: block;
	font-size: 10px;
}
.sidebar-nav ul li a {
	padding: 2px 13px 0;
}

.sidebar-nav > ul > li.active:hover > a {
	background: none;
}

.navigation-bar #sidebarnav a .count-badge {
	right: auto;
	left:  31px;
}
