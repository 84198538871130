.crop-favorite-row {
	display:             flex;
	flex:                1;
	flex-direction:      row;
	align-items:         center;
	padding-left:        10px;
	height:              50px;
	border-bottom-width: 1px;
	border-color:        #FFF;
}

.crop-favorite-row > span {
	flex: 1;
}

.crop-fast-selection .fj-btn {
	min-width: 200px;
}

.plan-crop-dialog {

}
.plan-crop-dialog .segmented-control-wrapper-1,
.plan-crop-dialog .segmented-control-wrapper-2 {
	display: block;
}

.plan-crop-dialog .segmented-control-container {
	display: inline-block;
}
