.field {
	display:        flex;
	flex:           1;
	flex-direction: column;
	height:         100%;
	max-width:      100%;
}

.comments {
	overflow-y: auto;
	flex:       1;
	-webkit-overflow-scrolling: touch;
}

.comments .comments-section-list {
	padding:    0 15px;
	margin-top: 10px;
}

.comments .comments-section-list, .comments .comments-section-list > ul > .section > * {
	transform: rotate(180deg)
}

.comment-form .text-input-container {
	margin-top:    0;
	margin-bottom: 0;
	line-height:   0;
}

.field-map .modal-body {
	padding: 0;
}

#field-dropzone {
	display:        flex;
	flex:           1 1;
	flex-direction: column;
	min-height:     1px;
}

#field-dropzone .overlay {
	position:         absolute;
	top:              0;
	left:             0;
	bottom:           0;
	right:            0;
	background-color: rgba(255, 255, 255, .7);
	z-index:          21;

	display:          flex;
	flex-direction:   column;
	justify-content:  center;
	align-items:      center;
}

#field-dropzone .overlay .message {
	font-size: 50px;
	text-align: center;
}

#field-dropzone > input {
	max-width: 100%;
}

/*.plan-crop-button:hover {*/
	/*color: #727b84;*/
/*}*/

.field-size-container .field-size-difference.difference {
	color: red;
}

.field-size-container .field-size-difference.equal {
	color: green;
}

.grouped-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.close-floating-column {
	background-color: transparent;
	border:           none;
	color:            #000;
	opacity:          .5;
	font-size:        25px;
	padding:          0.6rem 1rem;
	height:           54px;
	cursor:           pointer;
}

.field-navbar .badge-container {
	margin-right: 3px;
}

.create-field-dialog .segmented-control-wrapper-1, .create-field-dialog .segmented-control-wrapper-2 {
	display: block;
}

.create-field-dialog .segmented-control-wrapper-2 {
	text-align: left;
}

.create-field-dialog .segmented-control {
	padding-bottom: 0;
}

.field-info-balloon {
	display:        flex;
	flex-direction: column;
}

.field-info-balloon .hint {
	display:        flex;
	flex-direction: row;
}

.field-info-balloon .single-field-mark {
	margin-left: 0;
}

.field-info-balloon .hint__content {
	line-height: 1.5;
	font-family: sans-serif;
	font-weight: 300;
}
