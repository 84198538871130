.notification {
	cursor:         pointer;
	display:        flex;
	flex-direction: row;
	border-bottom:  1px solid #e6e6e6;
}

.notification.unread {
	background-color: rgba(0,122,255, 0.15);
}

.notification-container {
	padding: 10px 10px 10px 0;
	flex:    1;
}

.notification .avatar {
	margin: 10px;
}

.notification .notification-body {
	white-space: pre-line;
}

.notification .notification-footer {
	display:        flex;
	flex-direction: row;
}

.notification .notification-footer .name {
	flex: 1;
}