.service-worker-update-message {
	background:   #ffe821;
	box-shadow:   5px 0 10px rgba(0, 0, 0, .5);
	margin-left:  -15px;
	margin-right: -15px;
	text-align:   center;
	padding:      4px;
	height:       38px;
	z-index:      1;
	position:     relative;
}

.map-page .service-worker-update-message {
	margin-left:  0;
	margin-right: 0;
}

.topbar ~ .service-worker-update-message ~ .page-wrapper {
	padding-top: calc(60px + 38px);
	margin-top:  calc(-60px - 38px);
}