.crop-header-container .print {
	border:        none;
	border-radius: 19px;
	height:        37px;
	width:         37px;
	margin-right:  20px;
}

.comment {
	position: relative;
}

.comment .comment-menu-arrow {
	display:          flex;
	visibility:       hidden;
	opacity:          0;
	position:         absolute;
	right:            10px;
	top:              10px;
	height:           37px;
	width:            37px;
	background-color: #fff;
	justify-content:  center;
	align-content:    center;
	/* box-shadow: -10px 10px 20px 15px #f1f0f5; */
	box-shadow:       1px 0px 20px rgba(0, 0, 0, 0.08);
	border-radius:    19px;
	transition:       0.3s ease;
}

.comment .comment-menu-arrow i {
	display:     flex;
	align-items: center;
}

.comment:hover .comment-menu-arrow, .comment .comment-menu.show .comment-menu-arrow {
	visibility: visible;
	opacity:    1;
}

.comment > .comment-menu {
	position: static;
}