.image-comment {
    position: relative;
    overflow: hidden;
}

.image-comment .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
}

.image-comment .image {
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.image-comment .loading-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.fertilizing-comment {
    flex: 1;
}

.fertilizing-comment>.icon-header i {
    padding-right: 5px;
    color: #636363;
}

.fertilizing-comment>.title {
    margin-top: 10px;
}

.fertilizing-comment>.title .amount {
    min-width: 100px;
    display: inline-block;
    margin-right: 2px;
}

.fertilizing-comment .table-container {
    display: flex;
    flex: 1;
    justify-content: center;
}

.fertilizing-comment .table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 1;
}

.fertilizing-comment .table .f-column {
    display: flex;
    flex-direction: column;
}

.fertilizing-comment .table .f-header {
    font-weight: bold;
    flex: 1;
    text-align: center;
    padding: 3px 8px;
    flex-wrap: wrap;
    font-size: 16px;
}

.fertilizing-comment .table .f-content {
    flex: 1;
    padding: 3px 8px;
    text-align: center;
}

.fertilizing-comment .table .f-footer {
    background-color: #7EB549;
    border: 1px solid #7EB549;
    display: flex;
    flex-direction: column;
}

.fertilizing-comment .table .f-footer .f-content {
    color: #ffffff;
    font-weight: bold;
}

.fertilizing-comment .table .f-column:first-child .f-footer {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.fertilizing-comment .table .f-column:last-child .f-footer {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #7EB549;
}

@media (max-width: 768px) {
    .fertilizing-comment .table {
        font-size: 16px;
    }

    .fertilizing-comment .table .f-header,
    .fertilizing-comment .table .f-content {
        padding-left: 4px;
        padding-right: 4px;
    }
}


.comment-icon-header,
.crop-container-icon {
    display: flex;
    flex: 1;
    width: 100%;
}

.comment-icon-header .line,
.crop-container-icon .line {
    display: flex;
    flex: 1;
    align-items: center;
}

.comment-icon-header .line.left,
.crop-container-icon .line.left {
    margin-right: 20px
}

.comment-icon-header .line.right,
.crop-container-icon .line.right {
    margin-left: 20px
}

.comment-icon-header .line hr,
.crop-container-icon .line hr {
    flex: 1;
    color: #EEEEEE;
}

.comment-bonitur-preview {
    background: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

.comment-bonitur-preview-content {
    min-width: 300px;
}

.wait-time-pesticide-name {
    white-space: pre-line;
}
