.topbar {
	position: relative;
	top:      0px;
	width:    100%;
}

.topbar .top-navbar {
	max-width: none;
}

.topbar .branding {
	height: 65px;
}

.topbar .logo {
	height:       45px;
	margin-right: 10px;
	margin-left:  8px;
}

.topbar .logo-text {
	/* line-height: 0.6; */
	vertical-align: middle;
	font-size:      23px;
	margin:         0;
	display:        none;
	color:          #61af3c;
}

.topbar.open-companies .logo-text {
	display: inline-block;
}

.topbar .nav-link {
	min-width:  55px;
	text-align: center;
}

.topbar .navbar-light .top-nav .nav-item > a.nav-link {
	color: #ccc !important;
}

.topbar .navbar-light .top-nav .nav-item.active > a.nav-link {
	color: #fff !important;
}

.topbar .nav-link .avatar, .topbar .nav-link .avatar > img {
	max-height: 50px;
	width:      auto !important;
}

.topbar .navbar-header {
	display: flex;
}

.topbar .top-nav {
	/*  justify-content: flex-start; */
}

.topbar .title {
	font-size:       19px;
	color:           #3D312E;
	margin-bottom:   0;
	display:         flex;
	align-items:     center;
/*	flex:            0 0 300px; */
}

.topbar .title .title-container {
	cursor:          pointer;
	flex-direction:  column;
	display:         flex;
	align-items: flex-start;
}

.topbar .title .title-container > * {
	line-height: 1;
}

.topbar .title .info-row {
	margin-bottom: -2px;
	text-align: left;
}

.user-profile-button {
	display:     flex;
	height:      60px;
	align-items: center;
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link.toggle-companies,
.topbar .navbar-light .navbar-nav .nav-item > a.nav-link.open-invites,
.topbar .navbar-light .navbar-nav .nav-item a.nav-link.notifications {
	font-size: 24px;
	color:     #636363 !important;
}

.topbar.open-companies .navbar-header {
	width: 240px;
}

.open-invites .icon, .notifications .icon {
	font-size:      35px;
	vertical-align: middle;
	position:       relative;
}

.buttons-right {
	flex: 0 !important;
}

@media (max-width: 867px) {
	.topbar {
		position: relative;
		width:    100%;
	}
}

@media (max-width: 980px) {
	.topbar .title {
		flex-basis:  auto;
		margin-left: 20px;
	}
}
