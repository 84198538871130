.waittime {
	display:         flex;
	justify-content: center;
	align-items:     baseline;
	flex-direction:  row;
}

.waittime i {
	font-size:     56px;
	padding-right: 2px;
}

.waittime .days-left {
	font-size:   14px;
	white-space: nowrap;
}

.waittime i, .waittime .days-left {
	color: #ff3b30;
}

.waittime.done-waiting i, .waittime.done-waiting .days-left {
	color: #61af3c;
}

.waittime span {
	margin-right: 5px;
}

.waittime .applied-on, .waittime .ends-on {
	color: #999999;
}