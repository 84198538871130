.analyses {
	flex:           1;
	display:        flex;
	flex-direction: column;
}

.analyses .analyses-list {
	overflow:                   auto;
	flex:                       1;
	padding:                    0 15px;
	-webkit-overflow-scrolling: touch;
}

.analyses-list-item {
	margin:   0 10px 0 20px;
	cursor:   pointer;
	position: relative;
}

.analyses .section-list > ul > li {
	margin-bottom: 10px;
}

.new-analysis-dialog.form .modal-body {
	background-color: #e5e5e5;
	padding:          0;
}

.new-analysis-dialog.form .segmented-control-wrapper-1 {
	display: block;
}

.new-analysis-dialog.form .segmented-control-container {
	min-width: 360px;
}

.new-analysis-dialog.form .segmented-control-container a {
	flex: 1;
}

.new-analysis-dialog.form .segmented-control {
	padding-bottom: 0;
}

.analysis-pdf {
	display:        flex;
	flex:           1;
	flex-direction: column;
	position:       relative;
	min-height:     1px;
}

.analysis-pdf .link:not(.btn) {
	display:    inline-block;
	width:      200px;
	margin:     50px auto;
	font-size:  24px;
	text-align: center;
}

.analysis-pdf .link i {
	font-size:     60px;
	margin-bottom: 20px;
}

.analysis-pdf .link:hover i {
	color: inherit;
}

.analyses .notification-badge-container {
	position: absolute;
	left:     -13px;
	top:      3px;
}

.analyses .notification-badge-container .notification-badge {
	color:     #7EB549;
	font-size: 13px;
}

.analyses-invoice-list {
	margin: -15px;
}

.analysis-invoice-item {
	padding: 5px 15px;
}

.new-analysis-dialog .top-bar-button {
	margin: 0;
}
