.api-keys, .api-logs {
	flex:           1;
	display:        flex;
	flex-direction: column;
}

.api-keys .wrapper, .api-logs .wrapper {
	padding:    20px;
	flex:       1;
	min-height: 1px;
	overflow:   auto;
	-webkit-overflow-scrolling: touch;
}

.api-keys .api-key {
	display: flex;
}

.api-keys .edit-button {
	padding-top: 5px;
}

.api-keys .enable-switch-column {
	text-align: center;
}

.api-keys .edit-button-column {
	width: 74px;
}

.api-keys-dialog .loading-container {
	position:   absolute;
	left:       0;
	top:        0;
	right:      0;
	bottom:     0;
	background: rgba(255, 255, 255, .6);
	z-index:    1;
}

.api-keys .no-keys, .api-logs .no-results {
	padding-left: .75rem;
	text-align:   center;
}

.api-logs td.execution-time-column {
	white-space: nowrap;
}

.api-logs .result-column, .api-logs .version-column {
	text-align: center;
}

.api-logs .request-succeeded, .api-logs .request-failed {
	font-size: 20px;
}

.api-logs .request-succeeded {
	color: #63b03c;
}

.api-logs .request-failed {
	color: #fc4b6c;
}

.api-keys-column {
	margin-right: -15px;
}

.api-keys-card-wrapper {
	overflow-y:    auto;
	padding-right: 15px;
	-webkit-overflow-scrolling: touch;
}
