.bonitur-image-preview-comment {
    min-width: 100%;
    min-height: 200px;
    background-size: cover!important;
}

.fj-color-picker-renderer {
    display: inline-flex;
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.fj-color-picker-renderer-form {
    margin-left: 5px;
}

.fj-color-picker-renderer-table {
    margin-right: 5px;
}

.section-header {
  font-weight: bold !important;
}
