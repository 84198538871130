.column-layout {
	/*height: calc(*/
	/*100vh*/
	/*- 70px!* header *!*/
	/*- 52px!* navigation bar *!*/
	/*- 2 * 15px !*margins*!*/
	/*);*/
	height:          100%;
	justify-content: center;
	flex:            1;
	padding-right:   15px;
	min-height:      1px;
}

.column-layout > * {
	height:        calc(100% - 2 * 15px /*margins*/);
	overflow:      auto;
	flex:          1 1 0;
	position:      relative;
	margin-left:   15px;
	margin-top:    15px;
	margin-bottom: 15px;
	max-width:     450px;
	display:       none;
	-webkit-overflow-scrolling: touch;
}

.column-layout > *.medium-column {
	max-width: 600px;
}

.column-layout > *.wide-column {
	max-width: 900px;
}

.column-layout > *.unlimited-column {
	max-width: none;
}

.column-layout > *.floating-column {
	position:   absolute;
	right:      -510px;
	top:        60px;
	z-index:    10;
	height:     calc(100% - 60px/*header*/ - 29px/*footer*/ - 30px /*top and bottom margins*/);
	/*box-shadow: rgba(0, 0, 0, .5) 0 0 10px;*/
	transition: right .2s;
	overflow:   visible;
	max-width:  none;
	width:      450px;
}

.column-layout > *.floating-column.wide-column,
.column-layout > *.floating-column.wide-column > .floating-column-container > .card {
	width: 900px;
}

.column-layout > *.floating-column.wide-column {
	right: -1000px;
}

.column-layout > *.floating-column.open {
	right: 0;
}

.is-ios .column-layout > *.floating-column {
	height:     calc(100% - 60px/*header*/ - 29px/*footer*/ - 30px /*top and bottom margins*/ - 7px /*ios home bar*/);
}

.floating-column .card {
	width: 450px;
}

.floating-column-container, .sliding-column-container {
	display:        flex;
	flex-direction: row;
}

.column-open-close-button-container, .merged-control-container > div > .column-open-close-button-container {
	margin-top: 30px;
}

.column-open-close-button {
	height:          45px;
	padding:         10px;
	color:           #ffffff;
	background:      #1e88e5;
	font-size:       19px;
	line-height:     24px;
	margin-top:      1px;
	justify-content: center;
	align-items:     center;
}

.column-open-close-button .arrow {
	margin-left: 5px;
}

.column-layout > *.sliding-column {
	margin:     15px -465px 15px 15px;
	width:      450px;
	transition: margin-right .2s;
}

.column-layout > *.map-field-column {
	margin:       0 -450px 0 0;
	height:       100%;
}

.column-layout > *.sliding-column.open {
	margin-right: 0;
}

.column-layout > *.sliding-column > .sliding-column-container > .card {
	flex:   1;
	margin: 0;
}

.column-layout > *.map-fields-column {
	margin:     0 0 0 -600px;/* 0 0 0 calc(-4 * 150px); */
	max-width:  600px;/* calc(4 * 150px); */
	width:      600px; /*calc(4 * 150px); */
	transition: margin-left .2s;
	height:     100%;
}

.column-layout > *.map-fields-column.map-fields-column-field-list {
	margin:    0 0 0 -450px;
	width:     450px;
	max-width: 450px;
}

.column-layout > *.map-fields-column.open {
	margin-left: 0;
}

.column-layout > * > * {
	flex:       1 1 0;
	position:   relative;
	min-height: 1px;
}

.column-layout > * > .crop-rating-table {
	flex:       1 1 0;
	position:   relative;
	min-height: unset;
}

.column-layout > *.show {
	display:        flex;
	flex-direction: column;
	min-height:     1px;
}

/*.column-layout > *:last-child {*/
/*margin-right: 15px;*/
/*}*/

.column-layout > * > .card, .column-layout > .floating-column > .floating-column-container > .card {
	padding: 0;
	margin:  0;
}

.column-layout > * > .card > .card-body {
	display:    flex;
	min-height: 1px;
}

.column-layout .card.no-padding > .card-body {
	padding: 0;
}

.card-flex-column .card-body {
	flex-direction: column;
}

#react-select-portal {
	z-index:  10000;
	position: fixed;
	top:      0;
	left:     0;
}

.footer {
	left:           0;
	padding-top:    3px;
	padding-bottom: 3px;
	color: #3D312E;
}

.footer a small {
	color: #6D635D !important;
}

.footer a small:hover {
	color: #7EB549 !important;
}

.is-ios .footer {
	padding-bottom: 10px;
}

.companies ~ .footer {
	left: 60px;
}

.page-card-top-bar-container {
	display:          flex;
	flex-direction:   row;
	background-color: #fbfbfb;
	min-height:       45px;
	border-bottom:    1px solid #dee2e6;
}

.page-card-top-bar-title-container {
	min-width: 0;
	cursor: pointer;
	flex: 1;
}

.page-card-top-bar-title-text {
	font-weight: bold;
	color:      #636363;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
}
@media (min-width: 1105px) {
	/* 60px + 2 * 500px + 3 * 15px */
	/*.column-layout > *:first-child {*/
	/*margin-left: auto;*/
	/*}*/
	/*.column-layout > *:last-child {*/
	/*margin-right: auto;*/
	/*}*/
}

@media (max-width: 767px) {
	.column-layout {
		display: none;
	}
}
