.producers-table .table-container-2 {
    flex: 1;
}

.producers-table .table-footer {
    padding:    5px;
    border-top: 1px solid #aaa;
}

.producers-table .search-input {
    max-width: 350px;
    min-width: 100px;
}

.table-container .drag-handle{
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 10px;
    cursor: pointer;
    -webkit-transform: none!important;
    transform: none!important;
    z-index: 1;
}