.fj-switch .react-switch-bg {
    border: 2px solid #EEEEEE;
}

.fj-switch .react-switch-handle {
    box-shadow: 0px 0px 3px 1px #DDD;
}

.fj-switch.active .react-switch-handle {
    box-shadow: none;
    border: 1px solid #CCCCCC;
}
.fj-switch.active .react-switch-bg {
    border: none!important;
}
