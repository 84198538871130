.country-tel-select {
	min-width: 200px;
}

.country-tel-select__single-value {
	width:         100%;
	padding-right: 5px;
}

.country-input .text-input-container {
	margin-top:    0;
	margin-bottom: 0;
}

.react-select-dropdown-menu > div {
	overflow-x: hidden;
}