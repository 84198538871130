.employees-table .table-container-2 {
    flex: 1;
}

.employees-table .table-footer {
    padding:    5px;
    border-top: 1px solid #aaa;
}

.employees-table .search-input {
    max-width: 350px;
    min-width: 100px;
}

.employees-table .tab-view-list {
	flex:      1;
}
