.employees {
	flex:           1;
	display:        flex;
	flex-direction: column;
}

.employees .employees-list, .employees-on-field .employees-list {
	overflow: auto;
	flex:     1;
	-webkit-overflow-scrolling: touch;
}

.employees-on-field .modal-body {
	display:        flex;
	flex-direction: column;
}

.employee.form .role-segmented-control {
	display: table;
	width:   100%;
}

.employee.form .roles-select .segmented-control-container a {
	min-width: 10em;
}
.employee.form .roles-select .segmented-control {
	padding-bottom: 0;
}
.employee.form .fj-box > .content fieldset > *:last-child .segmented-control,
.employee.form .fj-box > .content > * > *:last-child > .segmented-control {
	padding-bottom: 0;
}

.employee.form .fj-box > .content > * > *:last-child,
.employee.form .fj-box > .content > * > fieldset > *:last-child {
	margin-bottom: 0;
}

.employee-item {
	cursor: pointer;
}

