.bubble-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
}

.bubble {
        border-radius: 10px;
        background-color: #F0EFEF;
        color: #3D312E;
        padding: 0 10px;
        margin: 3px 1px;
}

.bubble-close {
            margin-left: 5px;
            font-size: 16px;
            font-weight: bold;
            color: #cbcbcb;
            cursor: pointer;
}

.search-result-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 5px 10px 0;
}

.flex-row-center {
       display: flex;
       flex-direction: row;
       align-items: center;
}
