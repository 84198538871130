.invalid-label {
	color: #dc3545;
}

.color-picker {
	margin-bottom: 25px;
}

.color-picker > div {
	display: flex;
	justify-items: start;
	flex-wrap: wrap;
}

.color-picker > div > div {
	cursor: pointer;
}

.color-picker .selected > div {
	position: relative;
}

.color-picker .selected > div > div {
	border-style: solid;
}

.boolean {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.boolean > label {
	flex: 1;
}

.color-picker.has-error, .boolean.error {
	color: #fc4b6c;
}

.segmented-control-wrapper-1 {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.segmented-control-wrapper-1 .segmented-control-label {
	margin-right: 10px;
}

.segmented-control-container a.active {
	border-color: rgba(0, 0, 0, 0.125);
}

.segmented-control-wrapper-1.error a:not(.active) {
	color: #fc4b6c;
}

.segmented-control-wrapper-1.error a.active, .segmented-control-wrapper-1.error a.active:hover, .segmented-control-wrapper-1.error a:hover {
	background-color: #fc4b6c;
	border-color: #fc4b6c;
	color: #fff;
}

.segmented-control-wrapper-1.isDisabled a {
	pointer-events: none;
	color: #ABA7A4;
	border-color: #ABA7A4;
}

.segmented-control {
	display: flex;
	flex-direction: row;
        align-items: baseline;
}

.segmented-control input[type="radio"] {
	display: none;
}

.segmented-control .list-group-item {
	display: inline-block;
	padding: 0.35rem 0.6rem;
	margin-bottom: 0;
}

.segmented-control .list-group-item:first-child {
	border-top-right-radius: 0;
	border-bottom-left-radius: 4px;
}

.segmented-control .list-group-item:last-child {
	border-top-right-radius: 4px;
	border-bottom-left-radius: 0;
}

.segmented-control .list-group-item:not(:last-child) {
	border-right: none;
}

.rc-calendar-today .rc-calendar-date {
	background-color:#7EB549;
	border-color:#7EB549;
	color:#fff;
}

.rc-calendar-picker {
	z-index: 2000;
}

.position-on-map-with-nav-map .modal-body {
	padding: 0;
}

.position-on-map-with-nav-map .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.position-on-map-with-nav-map .header .btn {
	margin-top: -6px;
	margin-bottom: -6px;
}

.multi-select {
	margin-bottom: 15px;
}

.multi-select .item {
	padding-left: 10px;
	padding-right: 10px;
}

.multi-select .item:hover {
	background-color: #7EB5494d;
}

.modal-date-factory, .modal-date {
	margin-bottom: 15px;
}

.form .segmented-control-container {
	margin: 0 auto;
}

.form .segmented-control-wrapper-1 {
	display: table-row;
}

.form .segmented-control-label {
	/*display: table-cell;*/
}

.form .segmented-control {
	padding-bottom: 15px;
}

.form .segmented-control-wrapper-2 {
	display: table-cell;
	width: 100%;
	text-align: center;
}

.form .segmented-control-container {
	display: inline-block;
}

.form .segmented-control-container a {
	min-width: 5em;
	word-break: break-all;
}

.acl-text {
	color: #636363;
	margin-bottom: 10px;
	display: inline-block;
}

.textbox-plus-minus {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.textbox-plus-minus .text-input-container {
	margin-right: 20px;
}

.textbox-plus-minus .buttons {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}

.textbox-plus-minus .plus, .textbox-plus-minus .minus {
	border-color: #7EB549;
	border-width: 1px;
	border-style: solid;
	padding: 1px 8px;
	border-radius: 16px;
	line-height: 0;
	font-size: 28px;
	color:#7EB549;
	cursor: pointer;
}

.textbox-plus-minus .minus {
	margin-right: 20px;
}

.position-form-field .position-props {
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-between;
}

.position-form-field .position-props > :first-child {
	margin-right: 15px;
}

.favorite i {
	color:#7EB549;
	font-size: 28px;
	line-height: 1;
}

.favorite button {
	background-color: #fff;
	padding: 0;
	line-height: 0;
	margin-left: 15px;
}

.favorite button:active, .favorite button:focus {
	outline: none;
	box-shadow: none;
}

.form .field-size-container {
	display: flex;
	flex-direction: row;
	margin-top: 15px;
	margin-bottom: 20px;
}

.form .field-size-container .text-input-container {
	flex: 1;
	margin: 0;
}

.form .field-size-container .field-size-difference {
	display: flex;
	padding-top: 4px;
}

.form .field-size-container .field-size-difference span {
	margin-top: auto;
	margin-bottom: auto;
}

.emoji-picker.error {
	color: #fc4b6c;
}

.emoji-picker .emoji-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.emoji-picker .item {
	position: relative;
	border-width: 3px;
	border-style: solid;
	border-color: transparent;
	border-radius: 40px;
	padding: 9px;
	cursor: pointer;
	font-size: 35px;
	margin: 3px;
}

.emoji-picker .item.selected {
	border-color: #7EB549;
}

.emoji-picker .item > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	border-radius: 20px;
	background-color: transparent;
	border-width: 3px;
}

.radio-group-container {
	margin: 10px 0 0 0;
}

.radio-group-option {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.radio-group-text-muted {
	font-size: 16px;
	color: #636363;
}
.radio-group-label {
    font-size: 18px;
    color: #636363;
}

.radio-group-label-error {
    font-size: 18px;
    color: rgb(197,53,32);
}

.radio-group-option-icon {
	font-size: 20px;
	color: #039be5;
}
