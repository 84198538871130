.fertilizing-single-row-sum {

}

.fertilizing-single-row-sum span {
    display: inline-block;
    width: 55px;
    text-align: right;
    padding-right: 10px;
    padding-left: 10px;
    /*border-right: 1px solid #EEEEEE;*/
}

.fertilizing-single-row-sum span:first-child {
    width: 15px;
    text-align: left;
    padding: 0;
    border-right: 0;
}
.fertilizing-single-row-sum span:last-child {
    width: 60px;
    border-right: none;
}
