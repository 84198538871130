@font-face {
	font-family: 'FarmerJoe';
	font-style:  normal;
	font-weight: 400;
	src:         url('FarmerJoe.woff') format('woff'), /* Pretty Modern Browsers */ url('FarmerJoe.ttf') format('truetype'), /* Safari, Android, iOS */ url('FarmerJoe.svg#FarmerJoe') format('svg'); /* Legacy iOS */
}

.fj {
	font-family:             FarmerJoe;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing:  antialiased;
	display:                 inline-block;
	font-style:              normal;
	font-variant:            normal;
	text-rendering:          auto;
	line-height:             1
}

.fj-share_loc:before {
	content: "\e91b";
}
.fj-crosshair-half:before {
	content: "\e900";
}
.fj-search:before {
	content: "\e901";
}
.fj-sort:before {
	content: "\e902";
}
.fj-leaf:before {
	content: "\e903";
}
.fj-arrow_down:before {
	content: "\e904";
}
.fj-plus:before {
	content: "\e905";
}
.fj-arrow_left:before {
	content: "\e906";
}
.fj-arrow_right:before {
	content: "\e907";
}
.fj-arrow_up:before {
	content: "\e908";
}
.fj-camera:before {
	content: "\e909";
}
.fj-farm:before {
	content: "\e90a";
}
.fj-send:before {
	content: "\e90b";
}
.fj-time:before {
	content: "\e90c";
}
.fj-edit:before {
	content: "\e90d";
}
.fj-hourglass_green:before {
	content: "\e90e";
}
.fj-hourglass_red:before {
	content: "\e90f";
}
.fj-analysis:before {
	content: "\e910";
}
.fj-fertilization:before {
	content: "\e911";
}
.fj-user_outline:before {
	content: "\e912";
}
.fj-analysis_outline:before {
	content: "\e913";
}
.fj-user:before {
	content: "\e914";
}
.fj-barn:before {
	content: "\e915";
}
.fj-fields:before {
	content: "\e916";
}
.fj-fields_outline:before {
	content: "\e917";
}
.fj-crop_sown:before {
	content: "\e918";
}
.fj-truck:before {
	content: "\e919";
}
.fj-no_crop:before {
	content: "\e91a";
}
