body {
  margin: 0 !important;
  padding: 0;
  /*font-weight: 400;*/
  font-size: 18px;
  line-height: 1.5;
  color: #3D312E;
}

.marker-label,
.map-toolbar,
.map-toolbar-horizontal {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: #aaa;
}

::-webkit-scrollbar-button:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #efefef;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

a.blue-link,
a.blue-link:visited {
  color: #6D635D;
}

a.link:hover,
a.link:focus {
  color: #7EB549 !important;
}

.sidebar-nav ul li:not(.active) a:not(:hover) {
  color: #6D635D !important;
}

.companies .sidebar-nav ul li:not(.active) a:not(:hover) {
  color: #ffffff !important;
}

a.list-group-item.active,
a.list-group-item.active-red,
a.list-group-item.active-orange,
a.list-group-item.active-green,
a.list-group-item.active-gray,
.list-group a.list-group-item.active:hover,
.list-group a.list-group-item.active:visited {
  color: #fff;
}

.page-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 29px;

  height: calc(100vh);
  position: relative;
  transition: none !important;
}

.is-ios .page-wrapper {
  padding-bottom: 36px;
}

.topbar~.page-wrapper {
  padding-top: 60px;
  margin-top: -60px;
}

#main-wrapper.logged-in .page-wrapper:not(.login):not(.register) {
  margin-left: 60px;
}

.navigation-bar~.page-wrapper {
  padding-top: 52px;
  margin-top: -52px;

  height: calc(100vh - 52px
      /*navigation bar*/
      + 11px
      /*¯\_(ツ)_/¯*/
    );
}

.no-footer.page-wrapper {
  padding-bottom: 0;
  height: 100vh;
}

.scroll-sidebar {
  max-width: none;
}

.sidebar-nav {
  text-align: center;
}

.left-sidebar+.page-wrapper {
  padding-top: 122px;
  margin-top: -122px;
}

.page-wrapper.open-companies {
  margin-left: 240px;
}

::placeholder {
  color: #b3b3b3;
  opacity: 1;
}

.modal-dialog {
  height: calc(100% - 60px);
}

.modal-content {
  -webkit-box-shadow: none;
  box-shadow: none;
  max-height: 100%;
}

.modal-body {
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.modal-title {
  flex-grow: 1;
  font-weight: bold;
}

.modal-header {
  background-color: #fbfbfb;
  min-height: 50px;
}

.modal-header .close {
  order: 1;
  float: none;
  margin-top: -1rem;
  color: #000;
  opacity: 0.5;
  font-size: 25px;
  padding: 0.6rem 1rem;
  height: 57px;
}

.modal-header .close span {
  height: 35px;
  display: inline-block;
  vertical-align: middle;
}

.modal-header .close:focus,
.modal-header .close:hover {
  text-decoration: none;
  opacity: 0.75;
}

.modal-xl .modal-dialog {
  width: calc(100vw - 30px);
  max-width: none;
}

.modal-footer {
  min-height: 75px;
}

.modal-footer .btn {
  margin-bottom: 5px !important;
}

@media (min-width: 1900px) {
  .modal-xl .modal-dialog {
    width: 1850px;
    max-width: none;
  }
}

.simple-table .d-table-cell {
  padding: 0 3px;
}

.simple-table .d-table-cell:first-child {
  padding-left: 0;
}

.simple-table .d-table-cell:last-child {
  padding-right: 0;
}

.dw-user-box {
  display: flex;
}

.btn>* {
  vertical-align: middle;
}

.top-bar-button {
  line-height: 1;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #7EB549;
  font-size: 20px;
}

.gray-text {
  color: #999999;
}

.grouped-buttons .top-bar-button.gray-text {
  padding: 0 5px;
}

.ril__toolbar {
  background-color: transparent;
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.94);
}

.ril__toolbarSide.ril-toolbar-right {
  background-color: rgba(0, 0, 0, 0.6);
  padding-left: 20px;
}

.ril__toolbarItemChild.download-button {
  height: 50px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  opacity: 0.7;
  font-size: 22px;
}

.ril__toolbarItemChild.download-button:hover {
  opacity: 1;
}

.rc-pagination {
  font-size: 1rem;
  margin: 0;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next,
.rc-pagination-item {
  height: 38px;
  line-height: 38px;
  min-width: 38px;
  border-radius: 4px;
}

.rc-pagination-options-quick-jumper button {
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: inherit;
}

.rc-pagination-options-quick-jumper input {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.rc-pagination select {
  width: auto;
}

.rc-pagination-item a {
  color: #727b84;
}

.rc-pagination-item:hover {
  border-color: #1e88e5;
}

.rc-pagination-item:hover a {
  color: #1e88e5;
}

.rc-pagination-item-active {
  background-color: #1e88e5;
  border-color: #1e88e5;
}

.rc-pagination-item-active a {
  color: #fff !important;
}

.rc-pagination-item-active:hover a {
  color: #fff;
}

.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
  color: #ccc;
}

.rc-pagination-jump-prev:hover:after,
.rc-pagination-jump-next:hover:after {
  color: #1e88e5;
}

.rc-pagination-jump-prev-custom-icon:after,
.rc-pagination-jump-next-custom-icon:after {
  color: #ccc;
}

.rc-pagination-jump-prev-custom-icon:hover:after,
.rc-pagination-jump-next-custom-icon:hover:after {
  color: #ccc;
}

.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-next {
  color: #1e88e5;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  color: #727b84;
}

.rc-pagination-prev,
.rc-pagination-next {
  border: 1px solid #727b84;
}

.rc-pagination-prev a,
.rc-pagination-next a {
  color: #727b84;
}

.rc-pagination-disabled a {
  color: #ccc;
}

.rc-pagination-options-quick-jumper input {
  background-color: #fff;
  border: 1px solid #727b84;
}

.rc-pagination-options-quick-jumper input:hover {
  border-color: #1e88e5;
}

.rc-pagination-options-quick-jumper button {
  color: rgba(0, 0, 0, 1);
  background-color: #fff;
  border-color: #727b84;
}

.rc-pagination-options-quick-jumper button:hover,
.rc-pagination-options-quick-jumper button:active,
.rc-pagination-options-quick-jumper button:focus {
  color: #1e88e5;
  background-color: #fff;
  border-color: #1e88e5;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  background-color: #fff;
  border: 1px solid #727b84;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #1e88e5;
}

.rc-pagination-simple .rc-pagination-simple-pager button {
  color: rgba(0, 0, 0, 1);
  background-color: #fff;
  border-color: #727b84;
}

.rc-pagination-simple .rc-pagination-simple-pager button:hover,
.rc-pagination-simple .rc-pagination-simple-pager button:active,
.rc-pagination-simple .rc-pagination-simple-pager button:focus {
  color: #1e88e5;
  background-color: #fff;
  border-color: #1e88e5;
}

.topbar .top-navbar {
  height: 60px;
  min-height: 60px;
}

.topbar .branding {
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.min-height-1 {
  min-height: 1px;
}

@media screen and (max-height: 700px) {
  .topbar .top-navbar .navbar-header {
    line-height: 60px;
  }

  .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
    padding: 0rem 0.3rem;
  }

  .user-profile-button {
    height: auto;
  }

  .topbar .nav-link {
    min-width: auto;
  }

  .left-sidebar {
    padding-top: 50px;
  }

  .navigation-bar~.page-wrapper {
    padding-top: 52px;
    margin-top: -52px;

    height: calc(100vh - 54px);
  }
}

.scrollable-content {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  overflow: auto;
}

.emoji-font {
  font-family: apple color emoji, segoe ui emoji, noto color emoji,
    android emoji, emojisymbols, emojione mozilla, twemoji mozilla,
    segoe ui symbol, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    Arial, sans-serif;
}

@media screen and (min-width: 768px) {
  .navbar-header {
    width: 60px;
  }
}

@media (max-width: 867px) {
  .topbar .top-navbar {
    padding: 0;
  }
}

.fj-pointer {
  cursor: pointer;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: none;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: none;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    /*Chrome, Safari */
    color-adjust: exact !important;
    /*Firefox*/
  }

  .print .unlimited-column,
  .print .companies,
  .print .page-card-top-bar-container,
  .print .comment-footer,
  .print .hide-print,
  .print .footer,
  .print .fields-wrapper,
  .print .map-column,
  .print .map-fields-column,
  .print .topbar,
  .print .companies,
  .print .fields-wrapper,
  .print .page-card-top-bar-container,
  .print #field-dropzone > div.scrollable-content > div > div.comments > div > ul > li > div,
  .print #field-dropzone > div.scrollable-content > div > div.comments > div > ul > li > ul > li:last-child,
  .print .comment-footer,
  .print .footer {
    display: none !important;
  }

  #main-wrapper,
  .page-wrapper,
  .row,
  .wide-column,
  .card,
  .card-body,
  .field,
  .field-dropzone,
  .scrollable-content,
  #field-dropzone > div.scrollable-content > div,
  .comments,
  .sesction-list,
  .comments-section-list,
  .comment {
    overflow: hidden !important;
    height: auto !important;
  }

  .wide-column,
  .card,
  .card-body,
  .field,
  .field-dropzone,
  .scrollable-content,
  .scrollable-content > div,
  .comments {
    display: block !important;
  }

  .page-wrapper {
    background: #ffffff;
  }

  #main-wrapper > div.page-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .column-layout {
    margin: 0 !important;
    pading: 0 !important;
    height: auto !important;
  }

  .wide-column {
    margin: 0 !important;
  }

  .card {
    border: 0;
  }

  .comments-section-list {
    margin: auto !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  #field-dropzone > div.scrollable-content > div > div.comments > div > ul > li > ul > li > div {
    position: relative;
    height: auto;
    overflow: hidden;
  }

  #field-dropzone > div.scrollable-content > div {
    overflow: hidden !important;
  }

  .row {
    padding: 0 !important;
    margin: 0 !important;
  }

  .show-print,
  .field-info {
    display: block !important;
  }

  #field-dropzone > div.scrollable-content > div > div.comments > div > ul > li > ul > li > div {
    break-inside: avoid;
  }
}
