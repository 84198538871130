.loading-container {
	text-align: center;
	align-self: center;
	flex: 1;
	align-items: center;
	justify-items: center;
	display: flex;
}

.loading-container .circular {
	margin: auto;
}