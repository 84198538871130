.fj-form-error {
  color: red;
}

.fj-slider-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #6D635D;
  padding-bottom: 10px;
}

.fj-slider-container {
  margin: 0 15px;
}

.fj-slider-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #6D635D;
  padding: 10px 0 10px 0;
}

.fj-slider-component {
  flex: 1;
}

.fj-slider-component .rc-slider {
  margin-top: 5px;
}

.fj-slider-value {
  min-width: 40px;
  text-align: left;
}

.fj-slider-input {
  max-width: 92px;
  padding: 0 5px;
  text-align: left;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #7EB549 !important;
}

.fj-slider-input::placeholder {
  color: #000 !important;
}

.rc-slider-track {
  background-color: #7EB549 !important;
}

.rc-slider-handle {
  border-color: #ffffff !important;
  box-shadow: 0px 6px 13px 0px #00000040 !important;
  width: 32px !important;
  height: 32px !important;
  margin-top: -15px !important;
}

.rc-slider-handle:focus {
  border-color: #ffffff !important;
  box-shadow: 0px 6px 13px 0px #00000040 !important;
}

.rc-slider-handle:hover {
  border-color: #ffffff !important;
}

.fj-slider-suffix {
  position: absolute;
  top: 8px;
  right: 10px;
}

.new-bonitur-dialog label {
  font-weight: 300;
}
.new-bonitur-dialog .segmented-control {
  margin-bottom: 0;
  padding-bottom: 0;
}

.new-bonitur-dialog .invalid-feedback {
  display: none !important;
}

.new-bonitur-dialog .floating-labels.focused label {
  font-size: 16px;
}

.fj-form-rating, .fj-form-color-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
}

.fj-form-rating-error, .fj-form-color-picker-error {
  border: 2px solid red;
}

.fj-form-rating-item {
  background-color: var(--selected-color);
  height: 60px;
  width: 60px;
  border: 1px solid #f0efef;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fj-form-color-picker-item {
  height: 60px;
  width: 60px;
  border: 1px solid #f0efef;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.fj-form-color-picker-icon:hover {
  opacity: 0.5 !important;
}

.fj-form-group-boolean { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
}

.custom-checkbox-container input[type="checkbox"] {
  position: relative;
  opacity: 1;
  left: 0;
  top: 20;
}

.custom-checkbox-container input[type="checkbox"] + label {
  padding: 0;
  line-height: 0;
  margin-left: 5px;
  height: auto;
}

.custom-checkbox-container input[type="checkbox"] + label:before,
.custom-checkbox-container input[type="checkbox"] + label:after {
  display: none;
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
}

.custom-checkbox-container input[type="checkbox"] {
  position: relative;
  opacity: 1;
  left: 0;
  top: 20;
}

.custom-checkbox-container input[type="checkbox"] + label {
  padding: 0;
  line-height: 0;
  margin-left: 5px;
  height: auto;
}

.custom-checkbox-container input[type="checkbox"] + label:before,
.custom-checkbox-container input[type="checkbox"] + label:after {
  display: none;
}

.bold {
  font-weight: bold;
}

.section-header {
  font-weight: bold !important;
}

.crop-rating-share-with-list {
        max-height: 200px;
        overflow-y: auto;
        margin: 10px 0;
}
