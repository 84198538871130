.fields-list-section-header {
	display:         flex;
	justify-content: center;
	flex-direction:  row;
	align-items:     center;
	cursor:          pointer;
}

.uncultivated-section-header, .harvested-section-header {
	display:         flex;
	flex-direction:  row;
	justify-content: space-between;
	align-items:     center;
	width:           100%;
	max-width:       327px;
}

.icon-header {
	flex-direction:  row;
	justify-content: center;
	align-items:     center;
	padding-top:     10px;
	padding-bottom:  5px;
	display:         flex;
}

.uncultivated-section-header, .harvested-section-header > .area {
	display:        flex;
	flex-direction: row;
}

.uncultivated-section-header, .harvested-section-header > .area > span {
	color:        #636363;
	margin-right: 12px;
}

.crop-fields-list-section {
	cursor: pointer;
}

.crop-header-container {
	display:         flex;
	padding-left:    10px;
	flex-direction:  row;
	align-items:     center;
	justify-content: space-between;
	border-radius:   5px;
	margin-right:    10px;
}

.crop-fields-item, .archive-item {
	margin: 0 10px 0 20px;
	cursor: pointer;
}

.crop-fields-item {
	display: none;
	position: relative;
}

.crop-fields-item.expanded {
	display: block;
}

.fields, .field-table, .employees-table, .producers-table {
	flex:           1;
	display:        flex;
	flex-direction: column;
}

.fields .crop-field-list, .fields .field-list-nearby, .fields .harvested-list {
	overflow: auto;
	flex:     1;
	padding:  0 5px 0 15px;
	-webkit-overflow-scrolling: touch;
}

.crop-fields-list-item > ul > li {
	margin-bottom: 10px;
}

.nearby-field-list-item {
	cursor:   pointer;
	position: relative;
}

.crop-fields-list-item {
	display: none;
}

.crop-fields-list-item.expanded {
	display: block;
}

.table-columns-dialog .modal-body {
	display:         flex;
	flex-direction:  row;
	justify-content: space-between;
}

.sortable-helper {
	z-index:         100000000;
	list-style-type: none;
}

.table-columns-dialog .chosen-columns, .table-columns-dialog .possible-columns {
	user-select: none;
}

.table-columns-dialog .chosen-columns {
	background-color: #e5e5e5;
}

.table-columns-dialog .chosen-columns li, .table-columns-dialog .possible-columns li, .sortable-helper {
	display:          flex;
	padding-bottom:   5px;
	padding-top:      5px;
	align-items:      center;
	line-height:      21px;
	font-size:        18px;
	min-height:       27px;
	background-color: #fff;
}

.table-columns-dialog .chosen-columns li .name, .table-columns-dialog .possible-columns li .name, .sortable-helper .name {
	flex:         1;
	font-size:    inherit;
	line-height:  inherit;
	height:       auto;
	margin-right: 10px;
}

.table-columns-dialog .chosen-columns li .remove-button, .table-columns-dialog .possible-columns li .remove-button, .sortable-helper .remove-button {
	padding:    0 10px;
	visibility: hidden;
	height:     21px;
	color:      #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.table-columns-dialog .chosen-columns li:hover .remove-button, .table-columns-dialog .possible-columns li:hover .remove-button, .sortable-helper:hover .remove-button {
	visibility: visible;
}

.table-columns-dialog .possible-columns li .name {
	margin: 0;
}

.table-columns-dialog .drag-handle, .sortable-helper .drag-handle {
	margin-right:     10px;
	cursor:           pointer;
	padding-left:     5px;
	padding-right:    5px;
	background-color: #e5e5e5;
	border-radius:    4px;
}

.table-columns-dialog .drag-handle-spacer {
	width:        20px;
	margin-right: 10px;
}

.TopRightGrid_ScrollWrapper .ReactVirtualized__Grid::-webkit-scrollbar {
	display: none;
}

.ReactVirtualized__Grid {
	-webkit-overflow-scrolling: auto !important;
}

.single-field {
	border-bottom-width: 1px;
	border-bottom-color: #C8C8C8;
        width: 100%;
}

.cropTag {
	border-radius: 5px;
	padding-left: 10px;
	overflow: hidden;
}

.column-mark {
	display: flex;
}

.single-field-mark {
	margin-left: -30px;
}

.single-field-mark .mark-container .reason {
	margin-left: 5px;
	color: #999999;
}
.single-field-mark .mark-container .badge-color {
	margin-right: 5px;
}

.fields .notification-badge-container {
	position: absolute;
	left:     -13px;
	top:      2px;
}

.fields .notification-badge-container .notification-badge {
	color:     #7EB549;
	font-size: 13px;
}

.fj-small {
	font-size: 16px;
}

.fj-muted {
	color: #636363;
}

.fj-tag {
	display: inline-block;
	padding: 2px 5px;
	margin: 2px 0;
	border-radius: 5px;
}

.fj-tag.green {
	background: #61af3c;
	color: #FFFFFF;
}

.filter-display-fertilizing {
	margin: 0 15px
}

.filter-display-fertilizing label {
	font-size: 18px;
}

.fj-filters .dropdown-menu {
	min-width: 250px;
}

.fields .date-range-filter {
	flex:   1;
}

.fields .date-range-filter-trigger {
	padding-bottom: 4px;
}
