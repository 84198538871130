.settings-dialog .modal-content {
	/*min-width: 615px;*/
	min-height: 600px;
}

.select-language .select-language__control {
	height: 54px;
	cursor: pointer;
}

.select-language .select-language__option {
	cursor: pointer;
}

.account-edit .modal-body {
	/*min-height: 700px;*/
}

.account-edit .modal-body .tabbar {
	display: flex;
	justify-content: space-around;
	margin: 1em auto;
}

.account-edit .modal-body .tabbar > .tab {
	flex: 1;
	text-align: center;
	margin: 0;
}

.account-edit .modal-body .tabbar > .tab.active{
	border-bottom: 1px solid rgb(97,175,60);
}


.cover-avatar-container {
	margin: -15px -15px 0 -15px;
}

.settings-dialog .avatar {
	box-shadow: 1px 1px 5px 1px #ccc;
}
