.company {
  width: 100%;
}

.company-map {
  height: 300px;
  position: relative;
}

.company-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  z-index: 100;
  position: relative;
}
