.fertilizings {
	/*margin-top:   30px;*/
}

/*.fertilizings .text {*/
	/*color:         #636363;*/
	/*font-weight:   bold;*/
	/*text-align:    center;*/
	/*margin-bottom: 20px;*/
/*}*/

.fertilizers-form .fertilizings-table {
	display:        flex;
	flex-direction: row;
	align-items:    center;
	border-bottom:  1px solid #CCCCCC;
}

.fertilizings .fertilizings-table .header-row {
	width:        50px;
	margin-right: 10px;
	text-align:   right;
}

.fertilizings .input-field-percent {
	width:            80px;
	margin-bottom:    -1px;
	padding-bottom:   1px;
	background-color: transparent;
	text-align:       center;
	border-bottom:    2px solid #7EB549;
}

.fertilizings .input-field-percent .header {
	color: #7EB549;
}

.fertilizings .column {
	display:         flex;
	flex:            1;
	justify-content: center;
	align-items:     center;
}

.fertilizings .header {
	text-align:  center;
	font-weight: bold;
	font-size:   18px;
}

.fertilizings .form-group {
	margin-bottom: 0;
	margin-top:    0;
}

.fertilizings-table .form-control, .fertilizings-table .form-control:focus {
	border-bottom: none;
}

.fertilizings-table .bar {
	display: none;
}
